import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration';
import historyAccessor from 'history-accessor';
import { userPaths } from 'routes/paths';
import { Actions as GridActions } from 'pages/PlayerSearch-v3/squad/redux/grid.controller';

class TransfersInExploreAndBrowseState {}

const defaultState: TransfersInExploreAndBrowseState = {};

const stateController = new StateController<TransfersInExploreAndBrowseState>(
    'SQUAD_TRANSFERS_IN/EXPLORE_AND_BROWSE',
    defaultState,
);

class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState));
        };
    }

    public static onDiscoverPlayersByAttributes() {
        return (dispatch, getState: () => AppState) => {
            historyAccessor.push(userPaths.search);
            setTimeout(() => {
                dispatch(GridActions.onDiscoverPlayersByAttributes());
            }, 0);
        };
    }
    public static onSearchAvailablePlayers() {
        return (dispatch, getState: () => AppState) => {
            historyAccessor.push(userPaths.search);
            setTimeout(() => {
                dispatch(GridActions.onSearchAvailablePlayers());
            }, 0);
        };
    }

    public static onSearchContractsExpiringPlayers() {
        return (dispatch, getState: () => AppState) => {
            historyAccessor.push(userPaths.search);
            setTimeout(() => {
                dispatch(GridActions.onSearchContractsExpiringPlayers());
            }, 0);
        };
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.transfersIn.exploreAndBrowse;
}

const reducer = stateController.getReducer();

export {
    Selectors as Selectors,
    reducer as Reducer,
    TransfersInExploreAndBrowseState as State,
    Actions as Actions,
    stateController as Controller,
};
