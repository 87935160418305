import { OpportunityItem } from 'api/transfers-out/models/transfers-out';

export const getPlayerOpportunities = (
    opportunities: OpportunityItem[],
    player: { playerId: number },
) => {
    const playerOpportunities = opportunities.filter(
        (opportunity) =>
            ((opportunity?.matchingPlayer?.player?.id === player?.playerId &&
                opportunity?.matchingPlayer?.relevance >= 0.6) ||
                opportunity?.playersWithRelevance?.some(
                    (item) => item?.player?.id === player?.playerId && item?.relevance >= 0.6,
                )) &&
            !opportunity?.matchingPlayer?.isPitched &&
            !opportunity?.playersWithRelevance?.some((item) => item?.isPitched),
    );

    return playerOpportunities;
};
