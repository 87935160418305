import { FormattedPriceModel } from 'api/core/formatted-price-model';
import { CurrencyModel } from 'api/common/models';
import { PlayerAdTypeEnumModel } from 'api/core/pitch-opportunity';

export type RecentPitches = {
    recentPlayers: RecentPlayers[];
};

export type RecentPlayers = {
    playerId: number;
    playerName: string;
    insertedAt: string | Date;
    matches?: number;
};

export type SquadInfo = {
    id: number;
    name: string;
    shortName: string;
};

export type OpportunitiesResponse = {
    opportunities: OpportunityItem[];
    newOpportunitiesCounter: number;
};

export type OpportunityItem = {
    playerAd: PlayerAd;
    matchingPlayer: MatchingPlayer;
    playersWithRelevance: MatchingPlayer[];
    isDismissed: boolean;
};

export type OpportunityItemWithRelevance = OpportunityItem & {
    relevance: number;
    selectedPlayerId?: number;
};

export type PlayerAd = {
    id: number;
    updatedAt: string;
    amount: FormattedPriceModel;
    sellOn: number;
    grossSalary: FormattedPriceModel;
    positionCode: string;
    positionName: string;
    type: PlayerAdTypeEnumModel;
    currency: CurrencyModel;
    minHeight: number;
    fromAge: number;
    toAge: number;
    isLeftFooted: boolean;
    updateAtFormatted: string;
    updateAgo: string;
    isOutdated: boolean;
    isUpdatable: boolean;
    createdBySquad: ShortSquadInfo;
    playingStyleId: number;
    playingStyleName: string;
    preferredNationalityAreaId: number;
    isAnonymised: boolean;
    isAgencyRecommendation: boolean;
};

export type MatchingPlayer = {
    isPitched: boolean;
    isRelevant: boolean;
    player: OpportunityPlayer;
    relevance: number;
    recommendedAmount: string;
    recommendedAmountUnformatted: number;
};

export type OpportunityPlayer = {
    id: number;
    englishShortName: string;
    positionId: number;
    positionName: string;
    positionCode: string;
    playingStyle: string;
};

export type ShortSquadInfo = {
    competition: CompetitionModel;
    id: number;
    isLogoRestricted: boolean;
    name: string;
    shortName: string;
    squadLogo: string;
};
export type CompetitionModel = {
    areaId: number;
    areaName: string;
    id: number;
    logo: string;
    name: string;
};

export enum PitchSortByKeyEnum {
    MostRecent = 0,
    TransferFeeLowToHigh = 1,
    TransferFeeHighToLow = 2,
    ByRelevance = 3,
}

export type GroupedSquadOpportunities = {
    [key: string]: OpportunityItem[];
};

export enum SubSquadFilterTypesEnum {
    Recommended = 'Recommended',
    AllPlayers = 'AllPlayers',
    NotAvailable = 'NotAvailable',
    Available = 'Available',
    Goalkeepers = 'Goalkeepers',
    Defenders = 'Defenders',
    Midfielders = 'Midfielders',
    Attackers = 'Attackers',
    LeftBack = 'Left Backs',
    RightBack = 'Right Backs',
    CentreBack = 'Centre Backs',
    DefensiveMidfielders = 'Defensive Midfielders',
    CentralMidfielders = 'Central Midfielders',
    AttackingMidfielders = ' Attacking Midfielders',
    Wingers = 'Wingers',
    Forwards = 'Forwards',
}

export enum PlayerAvailabilityStatusEnum {
    NotAvailable = 0,
    ToBuy = 1,
    ToLoan = 2,
    ToBuyAndLoan = 3,
    LoanedIn = 4,
    LoanedOut = 5,
}

export interface MySquadResponse {
    players: MySquadPlayer[];
    playersMarkedNotAvailable: number[];
}

export interface MySquadPlayer {
    id: number;
    shortName: string;
    englishShortName: string;
    currencyCode: string;
    isRatingGrowthExpected: boolean;
    currentSquad: {
        id: number;
        logo: string;
        name: string;
        shortName: string;
        clubId: number;
    };
    parentSquad: {
        id: number;
        logo: string;
        name: string;
        shortName: string;
        clubId: number;
    };
    firstPositionCode: PositionCode;
    secondPositionCode: PositionCode;
    age: number;
    contractExpirationInMonthes: number;
    contractExpiration: string;
    toLoan: {
        request: boolean;
        monthlyLoan: number;
    };
    toBuy: {
        request: boolean;
        askingPrice: number;
        sellOn: number;
    };
    loanedOut: boolean;
    loanedIn: boolean;
    isRecommended: boolean;
    rating: number;
    redText: string[];
    matches?: number;
    availabilityStatus: PlayerAvailabilityStatusEnum;
    isMarkedNotAvailable: boolean;
    potentialRating: number;
    recommendedBuyAmount: string;
    recommendedLoanAmount: string;
    xTv: {
        marketValue: number;
        marketValuePrice: FormattedPriceModel;
        marketValueString: string;
        marketValueLastUpdatedDate?: Date | null;
    };
    videos: VideoItemInfo[];
}

export type PositionCode = 'GK' | 'LB' | 'CB' | 'RB' | 'DM' | 'CM' | 'AM' | 'W' | 'F';

export interface MySquadTitle {
    id: number;
    name: string;
    count: number;
}

export type PitchItem = {
    playerId: number;
    playerName: string;
    savedPitchesSquadsNumber: number;
    testablePitchesSquadsNumber: number;
    savedSquads: PitchSquadInfo[];
    testableSquads: PitchSquadInfo[];
    declinedSquads: PitchSquadInfo[];
    isPitchSquadsNamesHidden: boolean;
};

interface PitchSquadInfo {
    squadId: number;
    squadName: string;
}
interface VideoItemInfo {
    id: number;
    url: string;
}
