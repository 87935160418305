import * as redux from 'redux';
import * as CommonController from 'pages/club/transfers-in/redux/transfers-in-common.controller';
import * as RequirementsController from 'pages/club/transfers-in/redux/transfers-in-requirements.controller';
import * as RibbonController from 'pages/club/transfers-in/redux/transfers-in-top-ribbon.controller';
import * as AllPitchesController from 'pages/club/transfers-in/redux/transfers-in-all-pitches.controller';
import * as SavedPitchesReducers from 'pages/club/transfers-in/components/saved-pitches/redux/root.reducer';
import * as CreateRequirementController from 'pages/club/transfers-in/redux/transfers-in-create-new-requirement.controller';
import * as ExploreAndBrowseController from 'pages/club/transfers-in/redux/transfers-in-explore-and-browse.controller';

export class ClubTransfersInState {
    common: CommonController.State;
    requirements: RequirementsController.State;
    ribbon: RibbonController.State;
    allPitches: AllPitchesController.State;
    savedPitches: SavedPitchesReducers.SavedPitchesState;
    createRequirement: CreateRequirementController.State;
    exploreAndBrowse: ExploreAndBrowseController.State;
}

export function combineReducers() {
    const ClubTransfersInReducers = {
        common: CommonController.Reducer,
        requirements: RequirementsController.Reducer,
        ribbon: RibbonController.Reducer,
        allPitches: AllPitchesController.Reducer,
        savedPitches: SavedPitchesReducers.combineReducers(),
        createRequirement: CreateRequirementController.Reducer,
        exploreAndBrowse: ExploreAndBrowseController.Reducer,
    };

    return redux.combineReducers(ClubTransfersInReducers);
}
