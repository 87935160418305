import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import {
    CoachingLicenseTypeEnum,
    HeadCoachExperienceEnum,
    SortByKeyEnum,
} from 'api/search-v3/model/search-request';
import { CancelTokenSource } from 'axios';
import { SearchMode } from 'pages/PlayerSearch-v3/shared-components/models/shared-models';
import { SearchSuggestionSubset } from 'api/search/model/suggestion-subsets';
import { Actions as GridActions, Selectors as GridSelectors } from './grid.controller';
import { StaffRolesSelectItem } from 'api/staff/staff-account/model';
import { LanguagesSelectItem } from 'api/staff/staff-profile/model';
import { NationalitySelectItem } from 'api/core/area-short-info';
import {
    AreaCriteria,
    AvailableLeagueCriteria,
    AvailableNationalityCriteria,
} from 'api/search-v3/model/search-filter-criteria';
import { IdName } from 'api/core/id-name';
import { CustomLeagueList } from 'api/search-v3/model/suggestion-list';
import { userActivityItem } from 'pages/PlayerSearch-v3/squad/redux/filter.controller';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import { Actions as CompetitionsListActions } from './competition-list.controller';
import CommonService from 'api/common/common.service';
import { StaffProfileService } from 'api/staff/staff-profile/staff-profile.service';
import { getCancelTokenSource } from 'axios-config';
import SearchService from 'api/search-v3/search.clubside.service';
import config from 'config';
import { userPaths } from 'routes/paths';

class SortItem {
    id: SortByKeyEnum;
    title: string;
    shortTitle: string;
    sortDirection: 'asc' | 'desc';
}

const sortInfoMap = new Map<SortByKeyEnum, SortItem>([
    [
        SortByKeyEnum.ByRelevant,
        {
            id: SortByKeyEnum.ByRelevant,
            title: 'Relevance',
            shortTitle: 'Relevance',
            sortDirection: 'desc',
        },
    ],
]);

export const staffSortList: Array<SortItem> = [sortInfoMap.get(SortByKeyEnum.ByRelevant)];

export interface StaffAttributesFilterData {
    isOverTwoYears: boolean;
    isOverFiveYears: boolean;
    isOverEightYears: boolean;
    isOverTenYears: boolean;
    hasPlayingCareer: boolean;
    isCurrentLeague: boolean;
    isCurrentAllLeagues: boolean;
    leaguesList: number[];
    leagueExperienceList: Array<CustomLeagueList>;
    nationalityList: number[];
    licenseTypeList: CoachingLicenseTypeEnum[];
    staffExperienceList: HeadCoachExperienceEnum[];
    currentStaffType: StaffRolesSelectItem | null;
    languages: LanguagesSelectItem[] | null;
    nationalities: NationalitySelectItem[] | null;
    currentLeagueList?: CustomLeagueList[];
}

export const getStaffAttributesStructure = (): StaffAttributesFilterData => {
    return {
        isOverTwoYears: false,
        isOverFiveYears: false,
        isOverEightYears: false,
        isOverTenYears: false,
        hasPlayingCareer: false,
        isCurrentLeague: false,
        isCurrentAllLeagues: false,
        leaguesList: [],
        nationalityList: [],
        licenseTypeList: [],
        staffExperienceList: [],
        leagueExperienceList: [],
        currentStaffType: null,
        languages: null,
        nationalities: null,
        currentLeagueList: [],
    };
};

export const getStaffAttributesFilterCriteria = (): StaffAttributesFilterData => {
    return {
        isOverTwoYears: false,
        isOverFiveYears: false,
        isOverEightYears: false,
        isOverTenYears: false,
        hasPlayingCareer: false,
        isCurrentLeague: false,
        isCurrentAllLeagues: false,
        leaguesList: [],
        nationalityList: [],
        licenseTypeList: [],
        staffExperienceList: [],
        leagueExperienceList: [],
        currentStaffType: null,
        languages: null,
        nationalities: null,
        currentLeagueList: [],
    };
};

export interface StaffAttributesFilter {
    filterData: StaffAttributesFilterData;
    licenseTypeListAll: IdName[];
    staffExperienceListAll: IdName[];
    availableNationalities: AvailableNationalityCriteria[];
}

export class SearchState {
    searchMode: SearchMode;
    searchSubset: SearchSuggestionSubset;
    searchSubsets: SearchSuggestionSubset[];
    sortBy: SortByKeyEnum;
    availableLeagues: AvailableLeagueCriteria[];
    currentCountry: AreaCriteria;
    currentLeague: IdName;
    staffAttributesStructure: StaffAttributesFilterData;
    staffAttributesFilter: StaffAttributesFilter;

    structureLoading: boolean;
    structureWasLoaded: boolean;

    staffAttributesFilterModal: StaffAttributesFilter;

    deleteModal: {
        isListDeleted: boolean;
        isModalOpen: boolean;
        deletedListId: number;
    };

    showOnlyFreeAgentStaff: boolean;
    staffTypesListAll: StaffRolesSelectItem[];
    languagesListAll: LanguagesSelectItem[];
    nationalitiesListAll: NationalitySelectItem[];
    userActivityData: userActivityItem[];
    eligibilityStaffTypeValue: StaffRolesSelectItem;
    isRedirectUrlUsed: boolean;
}

const defaultState: SearchState = {
    searchMode: SearchMode.Filtered,
    searchSubset: null,
    searchSubsets: [],
    sortBy: SortByKeyEnum.ByRelevant,
    availableLeagues: [],
    currentCountry: null,
    currentLeague: null,
    staffAttributesStructure: getStaffAttributesStructure(),
    staffAttributesFilter: {
        filterData: getStaffAttributesFilterCriteria(),
        licenseTypeListAll: [
            { id: CoachingLicenseTypeEnum.ProLicense, name: 'Pro Licences' },
            { id: CoachingLicenseTypeEnum.ALicense, name: 'A Licence' },
            { id: CoachingLicenseTypeEnum.BLicense, name: 'B Licence' },
            { id: CoachingLicenseTypeEnum.CLicense, name: 'C Licence' },
        ],
        staffExperienceListAll: [
            { id: HeadCoachExperienceEnum.OverTwoYears, name: 'Over 2yrs' },
            { id: HeadCoachExperienceEnum.OverFiveYears, name: 'Over 5yrs' },
            { id: HeadCoachExperienceEnum.OverEightYears, name: 'Over 8yrs' },
            { id: HeadCoachExperienceEnum.OverTenYears, name: '10yrs +' },
            { id: HeadCoachExperienceEnum.HasPlayingCareer, name: 'Has Playing Career' },
        ],
        availableNationalities: [],
    },
    structureLoading: false,
    structureWasLoaded: false,

    staffAttributesFilterModal: null,

    deleteModal: {
        isListDeleted: false,
        isModalOpen: false,
        deletedListId: null,
    },

    showOnlyFreeAgentStaff: false,
    staffTypesListAll: [],
    languagesListAll: [],
    nationalitiesListAll: [],
    userActivityData: [],
    eligibilityStaffTypeValue: null,
    isRedirectUrlUsed: false,
};

const stateController = new StateController<SearchState>(
    'STAFF_PRODUCT_SEARCH/FILTER',
    defaultState
);

class Actions {
    public static token: CancelTokenSource = null;

    public static init() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.loadCriteria());
            dispatch(Actions.loadLanguagesList());
            dispatch(Actions.loadNationalitiesList());
        };
    }

    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            const staffTypesListAll = Selectors.getStaffTypesList(getState());
            const eligibilityStaffFilterValue =
                Selectors.getEligibilityStaffFilterValue(getState());
            const isRedirectUrlUsed = Selectors.getRoot(getState()).isRedirectUrlUsed;
            dispatch(
                stateController.setState({
                    ...defaultState,
                    staffTypesListAll: !!staffTypesListAll.length
                        ? staffTypesListAll
                        : defaultState.staffTypesListAll,
                    eligibilityStaffTypeValue: isRedirectUrlUsed
                        ? eligibilityStaffFilterValue
                        : null,
                })
            );
        };
    }

    public static loadCriteria = () => {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ structureLoading: true }));

                if (Actions.token) {
                    Actions.token.cancel();
                }
                Actions.token = getCancelTokenSource();
                const criteria = await SearchService.getStaffCriteria(Actions.token.token);
                const structure = getState().squadSearch.filter;
                const justReleasedAge = { min: 16, max: 20 };

                dispatch(
                    stateController.setState((prevState: SearchState) => ({
                        ...prevState,
                        staffAttributesStructure: {
                            ...prevState.staffAttributesStructure,
                            leagueExperienceList: criteria?.customLeagueList
                                ? [
                                      ...criteria?.customLeagueList.map((item) => ({
                                          ...item,
                                          checked: false,
                                      })),
                                  ]
                                : [],
                            currentLeagueList: criteria?.customLeagueList
                                ? [
                                      ...criteria?.customLeagueList.map((item) => ({
                                          ...item,
                                          checked: false,
                                      })),
                                  ]
                                : [],
                        },
                        staffAttributesFilter: {
                            ...prevState.staffAttributesFilter,
                            filterData: {
                                ...prevState.staffAttributesFilter.filterData,
                                leagueExperienceList: criteria?.customLeagueList
                                    ? [
                                          ...criteria?.customLeagueList.map((item) => ({
                                              ...item,
                                              checked: false,
                                          })),
                                      ]
                                    : [],
                                currentLeagueList: criteria?.customLeagueList
                                    ? [
                                          ...criteria?.customLeagueList.map((item) => ({
                                              ...item,
                                              checked: false,
                                          })),
                                      ]
                                    : [],
                            },
                        },
                        availableLeagues: criteria?.availableLeagues,
                        currentCountry: criteria?.country,
                        currentLeague: criteria?.league,
                        structureLoading: false,
                        structureWasLoaded: true,
                    }))
                );
            } catch (error) {
                console.error(error);
            } finally {
                dispatch(stateController.setState({ structureLoading: false }));
            }
        };
    };

    public static backToSearch() {
        return (dispatch, getState: () => AppState) => {
            window.open(`${config.webAppUrl}${userPaths.playerSearch}`, '_self');
        };
    }

    public static initSorting(definedSorting: SortByKeyEnum = null) {
        return (dispatch, getState: () => AppState) => {
            const state = getState();
            const gridState = GridSelectors.getGridState(state);

            if (definedSorting != null) {
                dispatch(stateController.setState({ sortBy: definedSorting }));
                return;
            }

            dispatch(stateController.setState({ sortBy: SortByKeyEnum.ByRelevant }));
        };
    }

    public static applySorting(sortBy: SortByKeyEnum, column: string) {
        return (dispatch, getState: () => AppState) => {
            const gridState = GridSelectors.getGridState(getState());

            dispatch(stateController.setState({ sortBy }));
            dispatch(GridActions.resetPage());
            dispatch(GridActions.refresh());
        };
    }

    public static setSearchModeToFiltered() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ searchMode: SearchMode.Filtered }));
        };
    }

    public static setShowOnlyFreeAgentStaff(value: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ showOnlyFreeAgentStaff: value }));
            dispatch(GridActions.resetPage());
            dispatch(GridActions.refresh());
        };
    }

    public static setEligibilityStaffTypeValue(selected: StaffRolesSelectItem) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ eligibilityStaffTypeValue: selected }));
            dispatch(GridActions.resetPage());
            dispatch(GridActions.refresh());
        };
    }

    public static setIsRedirectUrlUsed(value: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isRedirectUrlUsed: value }));
        };
    }

    public static toggleStaffAttributesFilterModal(isOpen: boolean) {
        return (dispatch, getState: () => AppState) => {
            const filter = Selectors.getStaffAttributesFilter(getState());
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    staffAttributesFilterModal: isOpen ? { ...filter } : null,
                }))
            );
        };
    }

    public static clearStaffAttributesFilter() {
        return (dispatch) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    staffAttributesFilter: {
                        ...prevState.staffAttributesFilter,
                        filterData: {
                            ...prevState.staffAttributesFilter.filterData,
                            ...prevState.staffAttributesStructure,
                        },
                    },
                }))
            );
        };
    }

    public static clearStaffAttributesFilterAsync() {
        return (dispatch) => {
            dispatch(Actions.clearStaffAttributesFilter());
            dispatch(GridActions.resetPage());
            dispatch(GridActions.refresh());
        };
    }

    public static clearStaffFreeAgentFilter() {
        return (dispatch) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    showOnlyFreeAgentStaff: false,
                }))
            );
        };
    }

    public static clearEligibilityStaffTypeFilter() {
        return (dispatch) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    eligibilityStaffTypeValue: null,
                }))
            );
        };
    }

    public static resetStaffFilter() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.clearStaffFreeAgentFilter());
            dispatch(Actions.clearEligibilityStaffTypeFilter());
            dispatch(Actions.clearStaffAttributesFilter());
        };
    }

    public static resetStaffFilterAsync() {
        return (dispatch) => {
            dispatch(Actions.resetStaffFilter());
            dispatch(GridActions.resetPage());
            dispatch(GridActions.refresh());
            // dispatch(Actions.clearAllUserActivityData());
        };
    }

    public static setStaffAttributesValue(e: any) {
        return (dispatch, getState: () => AppState) => {
            const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    staffAttributesFilterModal: {
                        ...prevState.staffAttributesFilterModal,
                        filterData: {
                            ...prevState.staffAttributesFilterModal.filterData,
                            [e.target.name]: value,
                        },
                    },
                }))
            );
        };
    }

    public static setStaffAttributesSelectValue(selectName: string, selected: any) {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    staffAttributesFilterModal: {
                        ...prevState.staffAttributesFilterModal,
                        filterData: {
                            ...prevState.staffAttributesFilterModal.filterData,
                            [selectName]: selected,
                        },
                    },
                }))
            );
        };
    }

    public static clearStaffAttributesFilterRowParams(params: string[]) {
        return (dispatch, getState: () => AppState) => {
            const structure = Selectors.getStaffAttributesFilterStructure(getState());
            // const filterDataState = Selectors.getPlayerAttributesModal(getState()).filterData;
            const clearParams = [...params];

            clearParams.forEach((param) => {
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        staffAttributesFilterModal: {
                            ...prevState.staffAttributesFilterModal,
                            filterData: {
                                ...prevState.staffAttributesFilterModal.filterData,
                                [`${param}`]: structure[`${param}`],
                            },
                        },
                    }))
                );
            });
        };
    }

    public static setStaffExperience(id: number) {
        return (dispatch, getState: () => AppState) => {
            const filterDataState = Selectors.getStaffAttributesModal(getState()).filterData;
            const staffExperienceList = filterDataState.staffExperienceList;
            const { OverTwoYears, OverFiveYears, OverEightYears, OverTenYears, HasPlayingCareer } =
                HeadCoachExperienceEnum;

            let staffExperienceListUpdated;
            if (staffExperienceList.includes(id)) {
                staffExperienceListUpdated = staffExperienceList.filter((x) => x != id);
            } else {
                if (id === OverTwoYears) {
                    staffExperienceListUpdated = [
                        ...staffExperienceList.filter(
                            (x) => x != OverFiveYears && x != OverEightYears && x != OverTenYears
                        ),
                        id,
                    ];
                }
                if (id === OverFiveYears) {
                    staffExperienceListUpdated = [
                        ...staffExperienceList.filter(
                            (x) => x != OverTwoYears && x != OverEightYears && x != OverTenYears
                        ),
                        id,
                    ];
                }
                if (id === OverEightYears) {
                    staffExperienceListUpdated = [
                        ...staffExperienceList.filter(
                            (x) => x != OverTwoYears && x != OverFiveYears && x != OverTenYears
                        ),
                        id,
                    ];
                }
                if (id === OverTenYears) {
                    staffExperienceListUpdated = [
                        ...staffExperienceList.filter(
                            (x) => x != OverTwoYears && x != OverFiveYears && x != OverEightYears
                        ),
                        id,
                    ];
                }
                if (id === HasPlayingCareer) {
                    staffExperienceListUpdated = [
                        ...staffExperienceList.filter((x) => x != HasPlayingCareer),
                        id,
                    ];
                }
            }

            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    staffAttributesFilterModal: {
                        ...prevState.staffAttributesFilterModal,
                        filterData: {
                            ...prevState.staffAttributesFilterModal.filterData,
                            staffExperienceList: [...staffExperienceListUpdated],
                        },
                    },
                }))
            );
        };
    }

    public static updateLeagueExperienceList(leagueId: number, action: string) {
        return (dispatch, getState: () => AppState) => {
            const leagueList = Selectors.getStaffAttributesModal(getState()).filterData.leaguesList;

            let updatedList = [];
            if (action === 'remove') {
                updatedList = leagueList.filter((id) => id !== leagueId);
            }
            if (action === 'add') {
                updatedList = [...leagueList, leagueId];
            }

            dispatch(
                stateController.setState((prevState) => {
                    return {
                        ...prevState,
                        staffAttributesFilterModal: {
                            ...prevState.staffAttributesFilterModal,
                            filterData: {
                                ...prevState.staffAttributesFilterModal.filterData,
                                leaguesList: updatedList,
                            },
                        },
                    };
                })
            );
        };
    }

    public static checkLeagueExperienceList(leagueId: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => {
                    return {
                        ...prevState,
                        staffAttributesFilterModal: {
                            ...prevState.staffAttributesFilterModal,
                            filterData: {
                                ...prevState.staffAttributesFilterModal.filterData,
                                leagueExperienceList: [
                                    ...prevState.staffAttributesFilterModal.filterData.leagueExperienceList.map(
                                        (item) => {
                                            if (item.id === leagueId) {
                                                return {
                                                    ...item,
                                                    checked: !item.checked,
                                                };
                                            }
                                            return item;
                                        }
                                    ),
                                ],
                            },
                        },
                    };
                })
            );
        };
    }

    public static setStaffLicence(id: number) {
        return (dispatch, getState: () => AppState) => {
            const filterDataState = Selectors.getStaffAttributesModal(getState()).filterData;
            const licenseTypeList = filterDataState.licenseTypeList;

            let licenseTypeListUpdated;
            if (licenseTypeList.includes(id)) {
                licenseTypeListUpdated = licenseTypeList.filter((x) => x != id);
            } else {
                licenseTypeListUpdated = [...licenseTypeList, id];
            }

            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    staffAttributesFilterModal: {
                        ...prevState.staffAttributesFilterModal,
                        filterData: {
                            ...prevState.staffAttributesFilterModal.filterData,
                            licenseTypeList: [...licenseTypeListUpdated],
                        },
                    },
                }))
            );
        };
    }

    public static applyStaffAttributesFilter() {
        return (dispatch, getState: () => AppState) => {
            // TODO: uncomment when User Activity for staff will be provided
            // dispatch(Actions.getCoachLeaguesUA());
            const filterData = Selectors.getStaffAttributesModal(getState()).filterData;
            const currentUserActivityData = Selectors.getRoot(getState()).userActivityData;

            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    staffAttributesFilter: {
                        ...prevState.staffAttributesFilter,
                        filterData: {
                            ...prevState.staffAttributesFilter.filterData,
                            ...filterData,
                        },
                    },
                }))
            );

            dispatch(GridActions.resetPage());
            dispatch(GridActions.refresh());

            dispatch(Actions.toggleStaffAttributesFilterModal(false));

            const staffExperienceItems = currentUserActivityData.filter(
                (item) => item.shouldToJoin
            );

            if (staffExperienceItems.length > 1) {
                // dispatch(Actions.headCoachExperienceUA(currentUserActivityData));
            } else {
                currentUserActivityData.map((item) => {
                    // dispatch(Actions.sendUserActivity(item.message, item.pageType));
                });
            }
        };
    }

    public static deleteCompetitionList(listId: number) {
        return (dispatch) => {
            dispatch(
                stateController.setState((draftState) => ({
                    ...draftState,
                    staffAttributesStructure: {
                        ...draftState.staffAttributesStructure,
                        leagueExperienceList: [
                            ...draftState.staffAttributesStructure.leagueExperienceList.filter(
                                (item) => item.id !== listId
                            ),
                        ],
                    },
                    staffAttributesFilter: {
                        ...draftState.staffAttributesFilter,
                        filterData: {
                            ...draftState.staffAttributesFilter.filterData,
                            leagueExperienceList: [
                                ...draftState.staffAttributesFilter.filterData.leagueExperienceList.filter(
                                    (item) => item.id !== listId
                                ),
                            ],
                        },
                    },
                }))
            );
            dispatch(
                stateController.setState((draftState) => {
                    if (draftState.staffAttributesFilterModal) {
                        return {
                            ...draftState,
                            staffAttributesFilterModal: {
                                ...draftState.staffAttributesFilterModal,
                                filterData: {
                                    ...draftState.staffAttributesFilterModal.filterData,
                                    leagueExperienceList: [
                                        ...draftState.staffAttributesFilterModal.filterData.leagueExperienceList.filter(
                                            (item) => item.id !== listId
                                        ),
                                    ],
                                },
                            },
                        };
                    }
                })
            );
        };
    }

    public static addLeagueList(list: CustomLeagueList) {
        return (dispatch, getState: () => AppState) => {
            const filterModal = Selectors.getStaffAttributesFilter(getState()).filterData;
            const currentList = filterModal.currentLeagueList.find((item) => item.id === list.id);

            if (currentList) {
                //need to change leagues list in AttributesFilter and AttributesFilterModal since add/remove not should change filters
                dispatch(
                    stateController.setState((draftState) => ({
                        ...draftState,
                        staffAttributesStructure: {
                            ...draftState.staffAttributesStructure,
                            leagueExperienceList:
                                draftState.staffAttributesStructure.leagueExperienceList.map(
                                    (item) => (item.id === list.id ? { ...list } : item)
                                ),
                        },
                        staffAttributesFilter: {
                            ...draftState.staffAttributesFilter,
                            filterData: {
                                ...draftState.staffAttributesFilter.filterData,
                                leagueExperienceList:
                                    draftState.staffAttributesFilter.filterData.leagueExperienceList.map(
                                        (item) => (item.id === list.id ? { ...list } : item)
                                    ),
                            },
                        },
                    }))
                );
                dispatch(
                    stateController.setState((draftState) => {
                        if (draftState.staffAttributesFilterModal) {
                            return {
                                ...draftState,
                                staffAttributesFilterModal: {
                                    ...draftState.staffAttributesFilterModal,
                                    filterData: {
                                        ...draftState.staffAttributesFilterModal.filterData,
                                        leagueExperienceList:
                                            draftState.staffAttributesFilterModal.filterData.leagueExperienceList.map(
                                                (item) => (item.id === list.id ? { ...list } : item)
                                            ),
                                    },
                                },
                            };
                        }
                    })
                );
            } else {
                dispatch(
                    stateController.setState((draftState) => ({
                        ...draftState,
                        staffAttributesStructure: {
                            ...draftState.staffAttributesStructure,
                            leagueExperienceList: [
                                ...draftState.staffAttributesStructure.leagueExperienceList,
                                list,
                            ],
                        },
                        staffAttributesFilter: {
                            ...draftState.staffAttributesFilter,
                            filterData: {
                                ...draftState.staffAttributesFilter.filterData,
                                leagueExperienceList: [
                                    ...draftState.staffAttributesFilter.filterData
                                        .leagueExperienceList,
                                    list,
                                ],
                            },
                        },
                    }))
                );
                dispatch(
                    stateController.setState((draftState) => {
                        if (draftState.staffAttributesFilterModal) {
                            return {
                                ...draftState,
                                staffAttributesFilterModal: {
                                    ...draftState.staffAttributesFilterModal,
                                    filterData: {
                                        ...draftState.staffAttributesFilterModal.filterData,
                                        leagueExperienceList: [
                                            ...draftState.staffAttributesFilterModal.filterData
                                                .leagueExperienceList,
                                            list,
                                        ],
                                    },
                                },
                            };
                        }
                    })
                );
            }
        };
    }

    public static clearUserActivityData(...ids: string[]) {
        return (dispatch, getState: () => AppState) => {
            let currentUserActivityData = Selectors.getRoot(getState()).userActivityData;

            currentUserActivityData = currentUserActivityData.filter(
                (item) => !ids.includes(item.id)
            );

            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    userActivityData: [...currentUserActivityData],
                }))
            );
        };
    }

    public static clearAllUserActivityData() {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    userActivityData: [],
                }))
            );
        };
    }

    public static clearAllCategory(startWith: string) {
        return (dispatch, getState: () => AppState) => {
            let currentUserActivityData = Selectors.getRoot(getState()).userActivityData;

            currentUserActivityData = currentUserActivityData.filter(
                (obj) => !obj.id.startsWith(startWith)
            );

            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    userActivityData: [...currentUserActivityData],
                }))
            );
        };
    }

    public static storeUserActivityData(
        message: string,
        pageType: string,
        userActivityId: string,
        shouldReplace: boolean = false,
        shouldToJoin: boolean = false
    ) {
        return (dispatch, getState: () => AppState) => {
            let currentUserActivityData = Selectors.getRoot(getState()).userActivityData;
            const isFoundIndex = currentUserActivityData.findIndex(
                (obj) => obj.id === userActivityId
            );

            if (isFoundIndex !== -1) {
                currentUserActivityData.splice(isFoundIndex, 1);
            }
            const shouldAdd = shouldReplace || isFoundIndex === -1;
            if (shouldAdd) {
                const uaData: userActivityItem = {
                    id: userActivityId,
                    message: message,
                    pageType: pageType,
                    shouldToJoin: shouldToJoin,
                };
                currentUserActivityData = [...currentUserActivityData, uaData];
            }

            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    userActivityData: [...currentUserActivityData],
                }))
            );
        };
    }

    public static sendUserActivity(message, pageType) {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                userActivityInsert({
                    PageName: pageType,
                    Message: message,
                    PageType: PageType.Search,
                })
            );
        };
    }

    public static openDeleteModal(id) {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    deleteModal: {
                        isModalOpen: true,
                        isListDeleted: false,
                        deletedListId: id,
                    },
                }))
            );
        };
    }

    public static closeDeleteModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    deleteModal: {
                        ...prevState.deleteModal,
                        isModalOpen: false,
                    },
                }))
            );
        };
    }

    public static confirmDeleteList() {
        return async (dispatch, getState: () => AppState) => {
            const deleteListId = Selectors.getRoot(getState()).deleteModal.deletedListId;
            await dispatch(CompetitionsListActions.competitionListDelete(deleteListId));
            dispatch(Actions.deleteCompetitionList(deleteListId));

            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    deleteModal: {
                        ...prevState.deleteModal,
                        isListDeleted: true,
                        deletedListId: null,
                    },
                }))
            );
        };
    }

    public static loadStaffTypesList = () => {
        return async (dispatch, getState: () => AppState) => {
            try {
                const { items } = await StaffProfileService.getStaffTypesForFilter();

                await dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        staffTypesListAll: items.map((item) => ({
                            ...item,
                            value: item.name,
                            label: item.name,
                        })),
                    }))
                );
            } catch (e) {
                console.error(e);
            }
        };
    };

    public static initHeaderSearchFilters = () => {
        return async (dispatch) => {
            dispatch(Actions.loadStaffTypesList());
        };
    };

    public static loadLanguagesList = () => {
        return async (dispatch, getState: () => AppState) => {
            try {
                const data = await CommonService.getLanguages();
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        languagesListAll: data.map((item) => ({
                            ...item,
                            value: item.name,
                            label: item.name,
                        })),
                    }))
                );
            } catch (e) {
                console.error(e);
            }
        };
    };

    public static loadNationalitiesList = () => {
        return async (dispatch, getState: () => AppState) => {
            try {
                const data = await CommonService.getNationalitiesList();
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        nationalitiesListAll: data.map((item) => ({
                            ...item,
                            ico: item.flagPath,
                            value: item.nationalityName,
                            label: item.nationalityName,
                        })),
                    }))
                );
            } catch (e) {
                console.error(e);
            }
        };
    };
}

class Selectors {
    public static getRoot = (state: AppState) => state.staffProductSearch.filter;
    public static getSortBy = (state: AppState) => Selectors.getRoot(state).sortBy;
    public static getDeleteModal = (state: AppState) => Selectors.getRoot(state).deleteModal;
    public static getStaffTypesList = (state: AppState) =>
        Selectors.getRoot(state).staffTypesListAll;
    public static getStaffAttributesFilter = (state: AppState) =>
        Selectors.getRoot(state).staffAttributesFilter;
    public static getEligibilityStaffFilterValue = (state: AppState) =>
        Selectors.getRoot(state).eligibilityStaffTypeValue;
    public static getStaffAttributesModal = (state: AppState) =>
        Selectors.getRoot(state).staffAttributesFilterModal;

    public static getStaffAttributesFilterStructure = (state: AppState) =>
        Selectors.getRoot(state).staffAttributesStructure;

    public static isStructureLoading = (state: AppState) =>
        Selectors.getRoot(state).structureLoading || !Selectors.getRoot(state).structureWasLoaded;

    public static isActiveStaffAttributesFilter = (state: AppState) => {
        return (
            JSON.stringify(Selectors.getStaffAttributesFilter(state).filterData) !==
            JSON.stringify(Selectors.getStaffAttributesFilterStructure(state))
        );
    };

    public static isChangedStaffAttributesFilter = (state: AppState) => {
        if (!Selectors.getStaffAttributesModal(state)) {
            return false;
        }
        return (
            JSON.stringify(Selectors.getStaffAttributesFilter(state).filterData) !==
            JSON.stringify(Selectors.getStaffAttributesModal(state).filterData)
        );
    };

    public static isAppliedStaffTypeFilter = (state: AppState) => {
        const filterData = Selectors.getStaffAttributesFilter(state)?.filterData;
        if (!filterData) {
            return false;
        }

        return filterData.currentStaffType !== null;
    };

    public static isAppliedStaffLeagueExperienceFilters = (state: AppState) => {
        const filterData = Selectors.getStaffAttributesFilter(state)?.filterData;
        const structure = Selectors.getStaffAttributesFilterStructure(state);
        if (!filterData) {
            return false;
        }
        const isCustomLeaguesSelected = filterData.leaguesList.length > 0;
        return (
            filterData.isCurrentLeague !== structure.isCurrentLeague ||
            filterData.isCurrentAllLeagues !== structure.isCurrentAllLeagues ||
            isCustomLeaguesSelected
        );
    };

    public static isAppliedLeagueExperience = (state: AppState) => {
        const filterData =
            Selectors.getStaffAttributesFilter(state)?.filterData.leagueExperienceList;
        if (!filterData) {
            return false;
        }
        return filterData.some((item) => item.checked);
    };

    public static isAppliedStaffLicenseType = (state: AppState) => {
        const filterData = Selectors.getStaffAttributesFilter(state)?.filterData;
        if (!filterData) {
            return false;
        }

        return filterData.licenseTypeList.length > 0;
    };

    public static isAppliedStaffExperienceFilters = (state: AppState) => {
        const filterData = Selectors.getStaffAttributesFilter(state)?.filterData;

        if (!filterData) {
            return false;
        }
        return filterData.staffExperienceList.length != 0;
    };

    public static isAppliedStaffNationalitiesFilter = (state: AppState) => {
        const filterData = Selectors.getStaffAttributesFilter(state)?.filterData;
        if (!filterData) {
            return false;
        }

        return filterData.nationalities?.length > 0;
    };

    public static isAppliedStaffLanguagesFilter = (state: AppState) => {
        const filterData = Selectors.getStaffAttributesFilter(state)?.filterData;
        if (!filterData) {
            return false;
        }

        return filterData.languages?.length > 0;
    };

    public static getStaffAttributesSelectedFormRowCount = (state: AppState) => {
        const isAppliedStaffTypeFilter = Selectors.isAppliedStaffTypeFilter(state);
        const isAppliedStaffLeagueExperienceFilters =
            Selectors.isAppliedStaffLeagueExperienceFilters(state);
        const isAppliedStaffExperienceFilters = Selectors.isAppliedStaffExperienceFilters(state);
        const isAppliedStaffLicenseType = Selectors.isAppliedStaffLicenseType(state);
        const isAppliedLeagueExperience = Selectors.isAppliedLeagueExperience(state);
        const isAppliedStaffNationalitiesFilter =
            Selectors.isAppliedStaffNationalitiesFilter(state);
        const isAppliedStaffLanguagesFilter = Selectors.isAppliedStaffLanguagesFilter(state);

        return [
            isAppliedStaffTypeFilter,
            isAppliedStaffExperienceFilters,
            isAppliedStaffLeagueExperienceFilters,
            isAppliedStaffLicenseType,
            isAppliedLeagueExperience,
            isAppliedStaffNationalitiesFilter,
            isAppliedStaffLanguagesFilter,
        ].filter(Boolean).length;
    };

    public static isActiveEligibilityStaffFilter = (state: AppState) =>
        Selectors.getEligibilityStaffFilterValue(state) !== null;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    SearchState as State,
    Actions,
    Selectors,
    stateController as Controller,
};
