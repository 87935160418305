import { FormattedPriceModel } from 'api/core/formatted-price-model';
import { CurrencyModel } from 'api/common/models';
import { IdName } from 'api/core/id-name';

export interface MyRequirementsPositionsResponse {
    items: MyRequirementsPosition[];
}
export interface MyRequirementsPosition {
    generalPositionId: number | null;
    isLeftFooted: boolean;
    isActive: boolean;
    name: string;
    newPitchesAmount: number | null;
}

export interface PositionRequirementsWithCountersResponse {
    ad: PositionRequirementModel;
    receivedPitchCount: number;
    savedPitchCount: number;
    declaredInterestCount: number;
}

export interface PositionRequirementModel {
    id: number;
    updatedAt: string;
    amount: FormattedPriceModel;
    sellOn: number;
    grossSalary: FormattedPriceModel;
    positionCode: string;
    positionName: string;
    type: PositionRequirementType;
    currency: CurrencyModel;
    minHeight: number;
    fromAge: number;
    toAge: number;
    updateAtFormatted: string;
    isOutdated: boolean;
    isUpdatable: boolean;
    isUpdated?: boolean;
    isLeftFooted: boolean;
    createdBySquad: ShortSquadInfo;
    isAgencyRecommendation: boolean;
    isAnonymised: boolean;
    playingStyleId: number;
    preferredNationalityAreaId: number;
    updateAgo: string;
}

export enum PositionRequirementType {
    Buy = 1,
    Loan = 2,
}

export interface PositionRequirementInitialInfoResponse {
    isPreferredPlayerAreaSelectorAvailable: boolean;
    preferredNationalityArea: IdName;
    preferredRegionArea: IdName;
    isAnonymisationAvailable: boolean;
    anonymisationCriteria: PlayerAdAnonymisationCriteria;
    positionStyles: PreferredPlayerPositionModel[];
}
export interface PreferredPlayerPositionModel {
    positionId: number;
    positionName: string;
    playingStyles: IdName[];
}
export interface PlayerAdAnonymisationCriteria {
    transferFeeThreshold: FormattedPriceModel;
    grossSalaryThreshold: FormattedPriceModel;
}
export interface CreatePositionRequirementsRequest {
    positionId: number;
    currencyId: number;
    minHeight?: number | null;
    maxHeight?: number | null;
    toAge?: number | null;
    fromAge?: number | null;
    isLeftFooted: boolean;
    type: PositionRequirementType;
    amount: number;
    sellOn?: number | null;
    sessionId?: string | null;
    grossSalary?: number | null;
    isAgencyRecommendation?: boolean;
    preferredNationalityAreaId?: number | null;
    playingStyleId?: number | null;
    isAnonymised: boolean;
}
export interface CreatePositionRequirementsResponse {
    playerAds: Array<{ id: number }>;
    totalClubsNotified: number;
}

export interface ShortSquadInfo {
    competition: CompetitionModel;
    id: number;
    isLogoRestricted: boolean;
    name: string;
    shortName: string;
    squadLogo: string;
}
export interface CompetitionModel {
    areaId: number;
    areaName: string;
    id: number;
    logo: string;
    name: string;
}
