import _ from 'lodash';
import { StateController } from 'utils/action-declaration';
import { PitchTypeEnum } from 'api/pitch-v2/models/pitch-type.model';
import { PitchModel as PitchModel, PitchType } from 'api/pitch-v2/models/pitch.model';
import { PlusPitchModel } from 'api/pitch-v2/models/plus-pitch.model';
import { ReceiverSuperPitch } from 'api/super-pitch/models/received-super-pitch';
import { AppState } from 'root.reducer';
import { AdTypeEnum } from 'api/pitch-v2/models/ad-type.model';
import { userPaths } from 'routes/paths';
import { Actions as ClassicPitchController } from 'pages/player-ads/redux/pitch/pitch.controller';
import { Actions as PlusPitchController } from 'pages/player-ads/redux/pitch/plus-pitch.controller';
import { Actions as SuperPitchController } from 'pages/player-ads/redux/pitch/super-pitch.controller';
import * as CommonController from 'pages/player-ads/redux/common.controller';
import { getCancelTokenSource } from 'axios-config';
import { PlayerAdModel } from 'api/player-ad-v2/models/player-ad-with-counters';
import { Actions as FindHeadCoachPopupController } from 'pages/landing-page/redux/find-coach-popup.controller';
import { PageType, ActionType } from 'constants/enums';
import {
    Actions as FilterActions,
    Selectors as FilterSelectors,
} from 'pages/club/transfers-in/components/saved-pitches/redux/filter.controller';
import historyAccessor from 'history-accessor';

const DEFAULT_PAGE_SIZE = 20;

export enum PaginationClickState {
    Number = 1,
    Left = 2,
    Right = 3,
}

export class SavedPitchesGridState {
    isLoading: boolean;
    selectedPitch: any;
    pagination: SavedPitchesPaginationState;
    pitches: PitchModel[];
    plusPitches: PlusPitchModel[];
    superPitches: ReceiverSuperPitch[];
}

export class SavedPitchesPaginationState {
    currentPage: number;
    pageCount: number;
    rowCount: number;
    pageSize: number;
    totalLength: number;
}

export class GroupedPitchModel {
    insertedAt: Date;
    pitch: PitchModel | PlusPitchModel | ReceiverSuperPitch;
    ad: PlayerAdModel;
}

const defaultPagination: SavedPitchesPaginationState = {
    currentPage: 0,
    pageCount: 0,
    rowCount: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    totalLength: 0,
};

const defaultState: SavedPitchesGridState = {
    isLoading: false,
    selectedPitch: null,
    pagination: defaultPagination,
    pitches: [],
    plusPitches: [],
    superPitches: [],
};

const stateController = new StateController<SavedPitchesGridState>(
    'SQUAD_TRANSFERS_IN/SAVED_PITCHES_GRID',
    defaultState,
);

class Actions {
    public static token = null;
    public static disposed = false;

    public static dispose() {
        return (dispatch) => {
            dispatch(
                stateController.setState({
                    ...defaultState,
                    pagination: { ...defaultPagination },
                }),
            );
            dispatch(FilterActions.dispose());
            if (Actions.token) {
                Actions.token.cancel();
                Actions.token = null;
                Actions.disposed = true;
            }
        };
    }

    public static loadAllSavedPitchesData() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));

            Actions.disposed = false;
            Actions.token = getCancelTokenSource();

            return Promise.allSettled([
                dispatch(CommonController.Actions.loadShortListedPlayers()),
                dispatch(ClassicPitchController.loadSavedPitches(Actions.token.token)),
                dispatch(PlusPitchController.loadSavedPlusPitches(Actions.token.token)),
                dispatch(SuperPitchController.loadSavedSuperPitches(Actions.token.token)),
            ])
                .then(async () => {
                    if (!Actions.disposed) {
                        const allPitchesCount = Selectors.selectAllSavedPitches(getState()).length;
                        if (allPitchesCount == 0) {
                            historyAccessor.push(userPaths.transfersInAllPitches);
                        } else {
                            dispatch(Actions.setPagination());
                            await dispatch(FilterActions.loadPlayerPositions()),
                                dispatch(stateController.setState({ isLoading: false }));
                        }
                    }
                })
                .catch((e) => console.error(e));
        };
    }

    public static setSelectedPitch(selectedPitch: object): any {
        return (dispatch) => {
            dispatch(stateController.setState({ selectedPitch: selectedPitch }));
        };
    }

    public static updatePagination(paginationDto: SavedPitchesPaginationState) {
        return (dispatch) => {
            dispatch(stateController.setState({ pagination: { ...paginationDto } }));
        };
    }

    public static onPageTurn(currentPage: number) {
        return (dispatch, getState: () => AppState) => {
            let pagination = Selectors.getPaginationState(getState());
            pagination.currentPage = currentPage;
            dispatch(stateController.setState({ pagination: { ...pagination } }));
        };
    }

    public static setPageSize = (page: number, pageSize: number) => {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    pagination: {
                        ...prevState.pagination,
                        currentPage: page,
                        pageSize,
                    },
                })),
            );

            dispatch(Actions.setPagination());
        };
    };

    public static setPagination() {
        return (dispatch, getState: () => AppState) => {
            const substate = Selectors.getRoot(getState());
            const pitchesCount = Selectors.selectFilteredPitchesCount(getState());

            let pagination = substate.pagination;

            if (pagination.pageSize > pitchesCount) {
                dispatch(Actions.resetPagination());
            } else {
                let pageCount = Math.ceil(pitchesCount / pagination.pageSize);

                pagination.currentPage =
                    pageCount < substate.pagination.currentPage
                        ? pageCount
                        : substate.pagination.currentPage === 0
                          ? 1
                          : substate.pagination.currentPage;
                pagination.pageCount = pageCount;
                pagination.rowCount = pitchesCount;
                pagination.totalLength = pitchesCount;
                dispatch(Actions.updatePagination(pagination));
            }
        };
    }

    public static resetPagination() {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState({
                    pagination: {
                        currentPage: 0,
                        pageCount: 0,
                        rowCount: 0,
                        pageSize: DEFAULT_PAGE_SIZE,
                        totalLength: 0,
                    },
                }),
            );
        };
    }

    public static openFindCoachPopup() {
        return (dispatch) => {
            dispatch(FindHeadCoachPopupController.openModal(PageType.PlayerAds));
        };
    }

    public static onBackButtonClick() {
        return (dispatch, getState: () => AppState) => {
            historyAccessor.goBack();
        };
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.transfersIn.savedPitches.savedPitchesGrid;

    public static selectFilteredPitchesCount(state): number {
        let pitches = state.playerAdPage.pitch.savedPitches;
        let plusPitches = state.playerAdPage.plusPitch.savedPitches;
        let superPitches = state.playerAdPage.superPitch.savedSuperPitches;

        pitches = Selectors.applyFilterForClassicPitches(state, pitches);
        plusPitches = Selectors.applyFilterForPlusPitches(state, plusPitches);
        superPitches = Selectors.applyFilterForSuperPitches(state, superPitches);

        return pitches.length + plusPitches.length + superPitches.length;
    }

    public static selectAllSavedPitches(state: AppState) {
        var allPitches = new Array<GroupedPitchModel>();
        let pitches = state.playerAdPage.pitch.savedPitches;
        let pitchAds = state.playerAdPage.pitch.savedPitchesPlayerAds;
        let plusPitches = state.playerAdPage.plusPitch.savedPitches;
        let superPitches = state.playerAdPage.superPitch.savedSuperPitches;

        pitches = Selectors.applyFilterForClassicPitches(state, pitches);
        plusPitches = Selectors.applyFilterForPlusPitches(state, plusPitches);
        superPitches = Selectors.applyFilterForSuperPitches(state, superPitches);

        const includeAgentPitches = FilterSelectors.getIncludeAgentPitches(state);
        const includeClubPitches = FilterSelectors.getincludeClubPitches(state);

        pitches.forEach((item) => {
            if (
                (item.type == PitchType.Agency && includeAgentPitches) ||
                (item.type == PitchType.Club && includeClubPitches)
            ) {
                allPitches.push({
                    insertedAt: item.insertedAt,
                    pitch: {
                        ...item,
                        pitchType: PitchTypeEnum.classicPitch,
                    },
                    ad: pitchAds.find((x) => x.id == item.playerAdId),
                } as GroupedPitchModel);
            }
        });
        plusPitches.forEach((item) => {
            if (
                (includeAgentPitches && item.isFromAgency) ||
                (includeClubPitches && !item.isFromAgency)
            ) {
                allPitches.push({
                    insertedAt: item.insertedAt,
                    pitch: {
                        ...item,
                        pitchType: PitchTypeEnum.plusPitch,
                    },
                } as GroupedPitchModel);
            }
        });
        superPitches.forEach((item) => {
            allPitches.push({
                insertedAt: item.insertedAt,
                pitch: {
                    ...item,
                    pitchType: PitchTypeEnum.superPitch,
                },
            } as GroupedPitchModel);
        });

        allPitches.sort(
            (a, b) => new Date(b.insertedAt).getTime() - new Date(a.insertedAt).getTime(),
        );

        allPitches = Selectors.getPitchesByPagination(state, allPitches);

        return allPitches;
    }

    private static applyFilterForClassicPitches(
        state: AppState,
        classicPitches: Array<PitchModel>,
    ): Array<PitchModel> {
        const criteria = FilterSelectors.getFilterCriteria(state);
        const savedPitchesPlayerAds = state.playerAdPage.pitch.savedPitchesPlayerAds;
        let leftFootedSavedPitches = [];
        classicPitches.map((item) => {
            let playerAd = savedPitchesPlayerAds.find((x) => x.id === item.playerAdId);
            if (playerAd !== null && playerAd?.isLeftFooted) {
                leftFootedSavedPitches.push(item);
            }
        });
        const includeAgentPitches = FilterSelectors.getIncludeAgentPitches(state);
        const includeClubPitches = FilterSelectors.getincludeClubPitches(state);
        let filteredClassicPitches = classicPitches.filter(
            (item) =>
                (criteria.isForSale && criteria.isForLoan) ||
                ((!criteria.isForSale || item.adType == AdTypeEnum.Buy) &&
                    (!criteria.isForLoan || item.adType == AdTypeEnum.Loan) &&
                    ((item.type == PitchType.Agency && includeAgentPitches) ||
                        (item.type == PitchType.Club && includeClubPitches))),
        );

        if (!criteria.positions.length) {
            if (criteria.isHeadCoach) {
                return (filteredClassicPitches = []);
            } else return filteredClassicPitches;
        }

        let positionsFilterBy = criteria.positions;
        if (criteria.isLeftFooted) {
            positionsFilterBy = positionsFilterBy.filter((x) => x !== 'CB');
        }

        filteredClassicPitches = filteredClassicPitches.filter(
            (x) =>
                positionsFilterBy.includes(x.player.firstPositionCode) ||
                positionsFilterBy.includes(x.player.secondPositionCode) ||
                (criteria.isLeftFooted && leftFootedSavedPitches.includes(x)),
        );

        return filteredClassicPitches;
    }

    private static applyFilterForPlusPitches(
        state: AppState,
        plusPitches: Array<PlusPitchModel>,
    ): Array<PlusPitchModel> {
        const criteria = FilterSelectors.getFilterCriteria(state);
        let plusPitchesFiltered = plusPitches;
        const includeAgentPitches = FilterSelectors.getIncludeAgentPitches(state);
        const includeClubPitches = FilterSelectors.getincludeClubPitches(state);
        if (!criteria.isForSale || !criteria.isForLoan) {
            plusPitchesFiltered = plusPitchesFiltered.filter(
                (item) =>
                    (!criteria.isForSale || item.costInfo.sale != null) &&
                    (!criteria.isForLoan || item.costInfo.loan != null),
            );
        }

        if (!includeAgentPitches || !includeClubPitches) {
            plusPitchesFiltered = plusPitchesFiltered.filter(
                (item) =>
                    (includeAgentPitches && item.isFromAgency) ||
                    (includeClubPitches && !item.isFromAgency),
            );
        }

        if (criteria.positions.length || criteria.isHeadCoach) {
            let positionsFilterBy = criteria.positions;

            if (criteria.isLeftFooted) {
                positionsFilterBy = positionsFilterBy.filter((x) => x !== 'CB');
            }

            plusPitchesFiltered = plusPitchesFiltered.filter(
                (item) =>
                    (criteria.positions.length &&
                        (positionsFilterBy.includes(item.player?.firstPositionCode) ||
                            positionsFilterBy.includes(item.player?.secondPositionCode))) ||
                    (criteria.isHeadCoach && item.clubStaff !== null),
            );
        }

        return plusPitchesFiltered;
    }

    private static applyFilterForSuperPitches(
        state: AppState,
        superPitches: Array<ReceiverSuperPitch>,
    ): Array<ReceiverSuperPitch> {
        const criteria = FilterSelectors.getFilterCriteria(state);
        let superPitchesFiltered = [];

        let positionsFilterBy = criteria.positions;
        if (criteria.isLeftFooted) {
            positionsFilterBy = positionsFilterBy.filter((x) => x !== 'CB');
        }
        const includeClubPitches = FilterSelectors.getincludeClubPitches(state);

        if (Array.isArray(superPitches)) {
            superPitchesFiltered = superPitches.filter(
                (item) =>
                    ((criteria.isForSale && criteria.isForLoan) ||
                        ((!criteria.isForSale ||
                            (item.buy != null && (!!item.buy.sellOn || item.buy.toBeDiscussed))) &&
                            (!criteria.isForLoan ||
                                (item.loan != null &&
                                    (!!item.loan.price || item.loan.toBeDiscussed))))) &&
                    (criteria.positions.length == 0 ||
                        positionsFilterBy.includes(item.playerInfo.position) ||
                        positionsFilterBy.includes(item.playerInfo.secondPosition)) &&
                    includeClubPitches,
            );
        }

        if (!criteria.positions.length && criteria.isHeadCoach) {
            return (superPitchesFiltered = []);
        }

        return superPitchesFiltered;
    }

    private static getPitchesByPagination(state: AppState, pitches: Array<GroupedPitchModel>) {
        let pagination = Selectors.getPaginationState(state);
        let numberFrom =
            pagination.currentPage <= 1 ? 0 : (pagination.currentPage - 1) * pagination.pageSize;
        let numberTo =
            pagination.currentPage <= 1
                ? pagination.pageSize
                : pagination.currentPage * pagination.pageSize;
        pitches = pitches.slice(numberFrom, numberTo);
        return pitches;
    }

    public static selectAllPitchesCount(state: AppState): number {
        const pitches = state.playerAdPage.pitch.savedPitches;
        const plusPitches = state.playerAdPage.plusPitch.savedPitches;
        const superPitches = state.playerAdPage.superPitch.savedSuperPitches;
        const count = pitches.length + plusPitches.length + superPitches.length;
        return count;
    }

    public static isAnySavedPitchExists(state: AppState): boolean {
        return Selectors.selectAllPitchesCount(state) > 0;
    }

    public static getPaginationState = (state: AppState) => Selectors.getRoot(state).pagination;
    public static getCurrentPage = (state: AppState) =>
        Selectors.getPaginationState(state).currentPage;
    public static getPageSize = (state: AppState) => Selectors.getPaginationState(state).pageSize;
    public static getTotalRowCount = (state: AppState) =>
        Selectors.getPaginationState(state).rowCount;
    public static getTotalPageCount = (state: AppState) =>
        Selectors.getPaginationState(state).pageCount;
    public static getIsLoading = (state: AppState) => Selectors.getRoot(state).isLoading;
    public static getSelectedPitch = (state: AppState) => Selectors.getRoot(state).selectedPitch;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    SavedPitchesGridState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller,
};
