import axios from 'axios-config';
import { CheckPlayerRequirementsResponse } from 'api/pitch-v2/models/check-player-requirements';

export class AdvisoryModalService {
    public static async checkPlayerRequirements(playerAdId: number, playerId: number) {
        const { data } = await axios.get<CheckPlayerRequirementsResponse>(
            `api/ClubAds/CheckPlayerRequirementsV2/${playerAdId}/${playerId}`,
        );
        return data;
    }
}
