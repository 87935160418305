import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration';
import historyAccessor from 'history-accessor';
import { userPaths } from 'routes/paths';
import * as CommonController from 'pages/player-ads/redux/common.controller';
import * as PitchController from 'pages/player-ads/redux/pitch/pitch.controller';
import * as PlusPitchController from 'pages/player-ads/redux/pitch/plus-pitch.controller';
import * as SuperPitchController from 'pages/player-ads/redux/pitch/super-pitch.controller';
import * as MyAdsController from 'pages/player-ads/redux/my-ads.controller';


class TransfersInAllPitchesState {
    isLoading: boolean;
}

const defaultState: TransfersInAllPitchesState = {
    isLoading: false,
};

const stateController = new StateController<TransfersInAllPitchesState>(
    'SQUAD_TRANSFER_IN/ALL_PITCHES',
    defaultState,
);

class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState));
            CommonController.Actions.cancelActivityQueries();
            PitchController.Actions.dispose();
            PlusPitchController.Actions.dispose();
            SuperPitchController.Actions.dispose();
            MyAdsController.Actions.dispose();
        };
    }

    public static onBackButtonClick() {
        return () => {
            historyAccessor.push(userPaths.transfersIn);
        };
    }

    public static init() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            try {
            } catch (error) {
                console.error(error);
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        };
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.transfersIn.allPitches;
    public static getIsLoading = (state: AppState) => Selectors.getRoot(state).isLoading;
}

const reducer = stateController.getReducer();

export {
    Selectors as Selectors,
    reducer as Reducer,
    TransfersInAllPitchesState as State,
    Actions as Actions,
    stateController as Controller,
};
