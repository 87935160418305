import * as redux from 'redux';
import * as TransfersOutCommonController from './transfers-out-common.controller';
import * as TransfersOutRibbonController from './transfers-out-ribbon.controller';
import * as TransfersOutTopOpportunitiesController from './transfers-out-top-opportunities.controller';
import * as TransfersOutPitchPage from './transfer-out-pitch-page.controller';
import * as TransfersOutMySquadController from './transfers-out-my-squad-controller';
import * as TransfersOutPlayerDetails from './transfer-out-player-detail.controller';
import * as TransfersOutInsights from './transfers-out-insights.controller';
import * as TransfersOutInsightsTab from './transfers-out-insights-tab.controller';
import * as TransfersOutPlayerController from 'pages/club/transfers-out-player/redux/transfers-out-player.controller';
import * as TransfersOutHighlights from './transfers-out-highlights.controller';

export class ClubTransfersOutState {
    common: TransfersOutCommonController.State;
    ribbon: TransfersOutRibbonController.State;
    topOpportunities: TransfersOutTopOpportunitiesController.State;
    mySquad: TransfersOutMySquadController.State;
    playerDetails: TransfersOutPlayerDetails.State;
    insights: TransfersOutInsights.State;
    insightsTab: TransfersOutInsightsTab.State;
    player: TransfersOutPlayerController.State;
    pitchedPlayer: TransfersOutPitchPage.State;
    highlights: TransfersOutHighlights.State;
}

export function combineReducers() {
    const ClubTransfersOutReducers = {
        common: TransfersOutCommonController.Reducer,
        ribbon: TransfersOutRibbonController.Reducer,
        topOpportunities: TransfersOutTopOpportunitiesController.Reducer,
        mySquad: TransfersOutMySquadController.Reducer,
        playerDetails: TransfersOutPlayerDetails.Reducer,
        insights: TransfersOutInsights.Reducer,
        insightsTab: TransfersOutInsightsTab.Reducer,
        player: TransfersOutPlayerController.Reducer,
        highlights: TransfersOutHighlights.Reducer,
        pitchedPlayer: TransfersOutPitchPage.Reducer,
    };

    return redux.combineReducers(ClubTransfersOutReducers);
}
