import {
    MODAL_CLOSE,
    MODAL_OPEN,
    MODAL_REFRESH_CONTENT,
    MESSAGES_SEND,
    COMPETITION_LIST_EDIT,
    COMPETITION_LIST_CREATE,
    COMPETITION_LIST_DELETE,
    COMPETITION_LIST_UPDATE,
    UPDATE_PASSWORD,
    UPDATE_PROFILE,
    ADD_THE_PROFILE_USERS,
} from '../actionTypes';
import {
    SEND_MESSAGE_MODAL,
    COMPETITION_LISTS_MODAL,
    USER_EDITOR_MODAL,
    CHANGE_PASSWORD_MODAL,
} from '../../constants/modals';

export type ModalState = {
    id: string;
};

const modalsReducer = (modals = [], action) => {
    switch (action.type) {
        case MODAL_CLOSE:
            return modals.filter((m) => m.id !== action.payload);

        case MODAL_OPEN: {
            return [...modals, action.payload];
        }

        case MODAL_REFRESH_CONTENT: {
            let result = modals.map((m) => {
                if (m.id === action.payload.id) {
                    m.content = action.payload.content;
                }
                return m;
            });
            return result;
        }

        case COMPETITION_LIST_CREATE.SUCCESS:
        case COMPETITION_LIST_UPDATE.SUCCESS:
        case COMPETITION_LIST_DELETE.SUCCESS: {
            return modals.filter((modal) => modal.id !== COMPETITION_LISTS_MODAL);
        }

        case MESSAGES_SEND.SUCCESS: {
            return modals.filter((modal) => modal.id !== SEND_MESSAGE_MODAL);
        }
        case COMPETITION_LIST_EDIT:
            return [...modals, { id: COMPETITION_LISTS_MODAL }];

        case UPDATE_PASSWORD.SUCCESS: {
            if (action.payload.data.isSuccess) {
                return modals.filter((modal) => modal.id !== CHANGE_PASSWORD_MODAL);
            }

            return modals;
        }

        case ADD_THE_PROFILE_USERS.SUCCESS:
        case UPDATE_PROFILE.SUCCESS: {
            return modals.filter((modal) => modal.id !== USER_EDITOR_MODAL);
        }

        default:
            return modals;
    }
};

export default modalsReducer;

export const getModals = (state) => state.modals;
