import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration';
import { MySquadToBuy, MySquadToLoan } from 'api/my-squad/model/my-squad-player';
import { Actions as SquadController } from 'pages/club/transfers-out/redux/transfers-out-my-squad-controller';
import TransfersOutService from 'api/transfers-out/transfers-out.service';
import TransfersOutPlayerDatailsService from 'api/transfers-out/player-datails/transfers-out-player-datails.service';
import {
    MySquadPlayer,
    OpportunityItem,
    PlayerAvailabilityStatusEnum,
    PositionCode,
} from 'api/transfers-out/models/transfers-out';
import {
    PlayerPositionTypeEnum,
    UpdatePlayerRequest,
} from 'api/transfers-out/models/transfers-out-player-datails.model';
import { Selectors as TransfersOutCommonSelectors } from 'pages/club/transfers-out/redux/transfers-out-common.controller';
import { getAuthSquadId, getAuthUserId } from 'store/auth/authReducer';
import { formatWithCommas } from 'services/utils';
import moment from 'moment';
import { convertToPlayableUrl } from 'utils/video-links';
import historyAccessor from 'history-accessor';
import { getToByToLoanAvalibilityStatus } from 'pages/club/helpers/avalibility-status';
import { userPaths } from 'routes/paths';
import { AdvisoryModalService } from 'api/pitch-v2/advisory-modal/advisory-modal.service';
import { PlayerRequirementMismatch } from 'api/pitch-v2/models/check-player-requirements';
import { getPlayerOpportunities } from '../utils';

class PlayerDetailsState {
    isLoading: boolean;
    selectedPlayer: MySquadPlayer;
    availabilitySaved: boolean;
    isPlayerInfoShown: boolean;
    playerOpportunities: OpportunityItem[];
    selectedPlayerOpportunity: OpportunityItem;
    deletedAdProcessingIds: number[];
    isPlayerAvailable: boolean;
    amount: number;
    activeTab: TableKey;
    managementTab: ManagementTabState;
    mismatches: PlayerRequirementMismatch[];
    isPlayerAdMismatchModelOpen: boolean;
}
interface ManagementTabState {
    playerId: number;
    activeTabId: string;
    processing: boolean;
    contractExpireDate: string;
    url: string;
    selectedRemoveReason: RemoveReasonsListItem | null;
    firstPositionCode: PositionCode | null;
    secondPositionCode: PositionCode | null;
}
export interface RemoveReasonsListItem {
    id: number;
    name: string;
}

export enum TableKey {
    Availability = 'availability',
    Opportunities = 'opportunities',
    Insights = 'insights',
    Management = 'management',
}

export enum ManagementTabKey {
    ContractExpiry = 'contractExpiry',
    PromoVideo = 'promoVideo',
    ChangePosition = 'changePosition',
    RemovePlayer = 'removePlayer',
}

const defaultState: PlayerDetailsState = {
    isLoading: false,
    availabilitySaved: false,
    selectedPlayer: null,
    isPlayerInfoShown: false,
    playerOpportunities: [],
    selectedPlayerOpportunity: null,
    deletedAdProcessingIds: [],
    isPlayerAvailable: true,
    amount: null,
    activeTab: TableKey.Availability,

    managementTab: {
        playerId: null,
        activeTabId: ManagementTabKey.ContractExpiry,
        processing: false,
        contractExpireDate: null,
        url: '',
        selectedRemoveReason: null,
        firstPositionCode: null,
        secondPositionCode: null,
    },

    mismatches: [],
    isPlayerAdMismatchModelOpen: false,
};

const stateController = new StateController<PlayerDetailsState>(
    'TRANSFER_OUT/PLAYER_DETAILS',
    defaultState,
);

class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState));
        };
    }

    public static setSelectedPlayer(player: MySquadPlayer) {
        return (dispatch) => {
            dispatch(stateController.setState({ selectedPlayer: { ...player } }));
            dispatch(Actions.setPlayerOpportunities(player));
            dispatch(Actions.setDefaultPlayerDetails());
            dispatch(Actions.setPlayerManagement(player));
        };
    }

    public static setActiveTab(key: TableKey) {
        return (dispatch) => {
            dispatch(stateController.setState({ activeTab: key }));
        };
    }

    public static setDefaultActiveTab() {
        return (dispatch) => {
            dispatch(stateController.setState({ activeTab: defaultState.activeTab }));
        };
    }

    public static onBackButtonClick() {
        return () => {
            historyAccessor.goBack();
        };
    }

    public static showPlayerInfo(isShow: boolean) {
        return (dispatch) => {
            dispatch(stateController.setState({ isPlayerInfoShown: isShow }));
        };
    }

    public static setAvailabilitySaved(isSaved: boolean) {
        return async (dispatch) => {
            dispatch(stateController.setState({ availabilitySaved: isSaved }));
        };
    }

    public static saveAvailability(playerId: number, toBuy: MySquadToBuy, toLoan: MySquadToLoan) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoading: true }));
                const player = Selectors.getSelectedPlayer(getState());
                const status = getToByToLoanAvalibilityStatus(toBuy, toLoan);

                const { availabilityStatus } =
                    await TransfersOutPlayerDatailsService.saveAvailability(
                        playerId,
                        toBuy,
                        toLoan,
                    );
                dispatch(
                    SquadController.makePlayerNotRecommended(
                        playerId,
                        availabilityStatus,
                        toBuy,
                        toLoan,
                    ),
                );

                const updatedPlayer = {
                    ...player,
                    toBuy: toBuy,
                    toLoan: toLoan,
                    isRecommended: false,
                    availabilityStatus: status,
                };

                dispatch(SquadController.updatePlayer(updatedPlayer));
                dispatch(Actions.updateSelectedPlayer(updatedPlayer));
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
                dispatch(Actions.setAvailabilitySaved(true));
            }
        };
    }

    public static setSelectedPlayerOpportunity(id: number) {
        return (dispatch, getState: () => AppState) => {
            const { playerOpportunities } = Selectors.getRoot(getState());

            const playerOpportunity = playerOpportunities.find((item) => item.playerAd.id === id);

            dispatch(
                stateController.setState({
                    selectedPlayerOpportunity: playerOpportunity,
                    amount: defaultState.amount,
                    isPlayerAvailable: defaultState.isPlayerAvailable,
                }),
            );
        };
    }

    public static setPlayerOpportunities(player: MySquadPlayer) {
        return (dispatch, getState: () => AppState) => {
            const { opportunities } = TransfersOutCommonSelectors.getRoot(getState());

            const playerOpportunities = getPlayerOpportunities(opportunities, {
                playerId: player.id,
            });
            const playerOpportunitiesSorted = playerOpportunities.sort((a, b) => {
                if (a.isDismissed !== b.isDismissed) {
                    return a.isDismissed ? 1 : -1;
                }
                return Date.parse(b.playerAd.updatedAt) - Date.parse(a.playerAd.updatedAt);
            });

            dispatch(
                stateController.setState({
                    playerOpportunities: playerOpportunitiesSorted,
                }),
            );
        };
    }

    public static dismissPlayerOpportunity = (createdBySquad: number, playerAdId: number) => {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoading: true }));

                const { playerOpportunities } = Selectors.getRoot(getState());

                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        deletedAdProcessingIds: [...prevState.deletedAdProcessingIds, playerAdId],
                    })),
                );

                await TransfersOutService.opportunityDecline(createdBySquad, playerAdId);

                const updatedOpportunities = playerOpportunities
                    .map((item) =>
                        item.playerAd.id === playerAdId ? { ...item, isDismissed: true } : item,
                    )
                    .sort((a, b) => {
                        if (a.isDismissed !== b.isDismissed) {
                            return a.isDismissed ? 1 : -1;
                        }
                        return 0;
                    });

                dispatch(
                    stateController.setState({
                        playerOpportunities: updatedOpportunities,
                    }),
                );

                dispatch(Actions.setDefaultPlayerDetails());
            } catch (error) {
                console.error(error);
            } finally {
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        deletedAdProcessingIds: prevState.deletedAdProcessingIds.filter(
                            (item) => item !== playerAdId,
                        ),
                        isLoading: false,
                    })),
                );
            }
        };
    };

    public static setIsPlayerAvailable(value: boolean) {
        return (dispatch) => {
            dispatch(stateController.setState({ isPlayerAvailable: value }));
        };
    }

    public static setDefaultPlayerDetails() {
        return (dispatch, getState: () => AppState) => {
            const { playerOpportunities } = Selectors.getRoot(getState());

            dispatch(
                stateController.setState({
                    selectedPlayerOpportunity: playerOpportunities[0],
                }),
            );
        };
    }

    public static setAmount(value: number) {
        return (dispatch) => {
            dispatch(
                stateController.setState({
                    amount: value,
                }),
            );
        };
    }

    public static checkPlayerRequirements({
        playerAdId,
        playerId,
    }: {
        playerAdId: number;
        playerId: number;
    }) {
        return async (dispatch) => {
            dispatch(stateController.setState({ isLoading: true }));

            try {
                const { mismatches } = await AdvisoryModalService.checkPlayerRequirements(
                    playerAdId,
                    playerId,
                );

                const isPlayerHasMismatchesWithAd = mismatches.length > 0;

                if (isPlayerHasMismatchesWithAd) {
                    dispatch(
                        stateController.setState({
                            mismatches,
                            isPlayerAdMismatchModelOpen: true,
                        }),
                    );
                }
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        };
    }

    public static pitchPlayer(
        playerAdId: number,
        playerId: number,
        amount: number,
        toBuy: MySquadToBuy,
        toLoan: MySquadToLoan,
    ) {
        return async (dispatch, getState: () => AppState) => {
            const { isPlayerAvailable } = Selectors.getRoot(getState());

            dispatch(Actions.closePlayerAdMismatchModal());

            dispatch(stateController.setState({ isLoading: true }));
            try {
                const currentUserId = getAuthUserId(getState());
                const currentSquadId = getAuthSquadId(getState());
                const { playerOpportunities } = Selectors.getRoot(getState());
                const player = Selectors.getSelectedPlayer(getState());
                const status = isPlayerAvailable
                    ? getToByToLoanAvalibilityStatus(toBuy, toLoan)
                    : PlayerAvailabilityStatusEnum.NotAvailable;

                await TransfersOutPlayerDatailsService.pitchPlayer(
                    currentUserId,
                    playerAdId,
                    amount,
                    playerId,
                    currentSquadId,
                    null,
                );

                if (isPlayerAvailable) {
                    await TransfersOutPlayerDatailsService.saveAvailability(
                        playerId,
                        toBuy,
                        toLoan,
                    );
                }

                dispatch(
                    stateController.setState({
                        playerOpportunities: playerOpportunities.filter(
                            (item) => item.playerAd.id !== playerAdId,
                        ),
                        selectedPlayerOpportunity: playerOpportunities[0],
                        amount: defaultState.amount,
                        isPlayerAvailable: defaultState.isPlayerAvailable,
                    }),
                );
                dispatch(Actions.setDefaultPlayerDetails());

                const updatedPlayer = {
                    ...player,
                    toBuy: toBuy,
                    toLoan: toLoan,
                    isRecommended: false,
                    availabilityStatus: status,
                };

                dispatch(SquadController.updatePlayer(updatedPlayer));
                dispatch(Actions.updateSelectedPlayer(updatedPlayer));
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        };
    }

    public static validateAndPitchPlayer({
        playerAdId,
        playerId,
        amount,
        toBuy,
        toLoan,
    }: {
        playerAdId: number;
        playerId: number;
        amount: number;
        toBuy: MySquadToBuy;
        toLoan: MySquadToLoan;
    }) {
        return async (dispatch, getState: () => AppState) => {
            try {
                await dispatch(Actions.checkPlayerRequirements({ playerAdId, playerId }));
                if (!Selectors.isPlayerAdMismatchOpen(getState())) {
                    dispatch(Actions.pitchPlayer(playerAdId, playerId, amount, toBuy, toLoan));
                }
            } catch (e) {
                console.error(e);
            }
        };
    }

    public static closePlayerAdMismatchModal() {
        return (dispatch) => {
            dispatch(stateController.setState({ isPlayerAdMismatchModelOpen: false }));
        };
    }

    /*----------------------------------- Management Tab --------------------------------------------------------------------------*/

    public static setActiveManagementTab(tabId: string) {
        return (dispatch, getState: () => AppState) => {
            const player = Selectors.getSelectedPlayer(getState());
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    managementTab: {
                        ...prevState.managementTab,
                        activeTabId: tabId,
                    },
                })),
            );
            dispatch(Actions.setPlayerManagement(player));
        };
    }

    public static setPlayerManagement(player: MySquadPlayer) {
        return (dispatch) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    managementTab: {
                        ...prevState.managementTab,
                        playerId: player?.id,
                        contractExpireDate: player?.contractExpiration,
                        url: player?.videos[0]?.url ?? '',
                        firstPositionCode: player?.firstPositionCode,
                        secondPositionCode: player?.secondPositionCode,
                        selectedRemoveReason: null,
                    },
                })),
            );
        };
    }

    public static setContractExpiryDate(date: string) {
        return (dispatch) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    managementTab: {
                        ...prevState.managementTab,
                        contractExpireDate: date,
                    },
                })),
            );
        };
    }

    public static savePlayerChanges() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    managementTab: {
                        ...prevState.managementTab,
                        processing: true,
                    },
                })),
            );

            const substate = Selectors.getManagementTabState(getState());

            const requestInfo: UpdatePlayerRequest[] = [
                {
                    playerId: substate.playerId,
                    contractExpiration: new Date(substate.contractExpireDate),
                    firstPositionCode: substate.firstPositionCode,
                    secondPositionCode: substate.secondPositionCode,
                },
            ];

            try {
                const player = Selectors.getSelectedPlayer(getState());

                await TransfersOutPlayerDatailsService.updatePlayers(requestInfo);

                const updatedPlayer = {
                    ...player,
                    contractExpiration: substate.contractExpireDate,
                    firstPositionCode: substate.firstPositionCode,
                    secondPositionCode: substate.secondPositionCode,
                };

                dispatch(SquadController.updatePlayer(updatedPlayer));
                dispatch(Actions.updateSelectedPlayer(updatedPlayer));
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        managementTab: {
                            ...prevState.managementTab,
                            processing: false,
                        },
                    })),
                );
            }
        };
    }

    public static setVideoUrl(url: string) {
        return (dispatch) => {
            let playableUrl = '';

            if (convertToPlayableUrl(url)) {
                playableUrl = convertToPlayableUrl(url);
            }

            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    managementTab: {
                        ...prevState.managementTab,
                        url: playableUrl,
                    },
                })),
            );
        };
    }

    public static savePlayerVideo() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    managementTab: {
                        ...prevState.managementTab,
                        processing: true,
                    },
                })),
            );

            try {
                const { playerId, url } = Selectors.getManagementTabState(getState());

                await TransfersOutPlayerDatailsService.saveVideos(playerId, [url]);
                const player = Selectors.getSelectedPlayer(getState());

                const updatedPlayer = {
                    ...player,
                    videos: [
                        {
                            id: null,
                            url: url,
                        },
                    ],
                };

                dispatch(SquadController.updatePlayer(updatedPlayer));
                dispatch(Actions.updateSelectedPlayer(updatedPlayer));
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        managementTab: {
                            ...prevState.managementTab,
                            processing: false,
                        },
                    })),
                );
            }
        };
    }

    public static removePlayerVideo() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    managementTab: {
                        ...prevState.managementTab,
                        processing: true,
                    },
                })),
            );

            try {
                const player = Selectors.getSelectedPlayer(getState());

                await TransfersOutPlayerDatailsService.saveVideos(player.id, []);

                const updatedPlayer = {
                    ...player,
                    videos: [],
                };

                dispatch(SquadController.updatePlayer(updatedPlayer));
                dispatch(Actions.updateSelectedPlayer(updatedPlayer));
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        managementTab: {
                            ...prevState.managementTab,
                            processing: false,
                            url: '',
                        },
                    })),
                );
            }
        };
    }

    public static setPlayerPosition(positionCode: string, positionType: PlayerPositionTypeEnum) {
        return (dispatch, getState: () => AppState) => {
            const substate = Selectors.getManagementTabState(getState());
            const isPrimary = positionType === PlayerPositionTypeEnum.PrimaryPosition;
            const primaryPosition = isPrimary ? positionCode : substate.firstPositionCode;
            const secondPosition = isPrimary
                ? positionCode === 'GK'
                    ? null
                    : substate.secondPositionCode
                : positionCode;
            if (primaryPosition === secondPosition) {
                return null;
            } else {
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        managementTab: {
                            ...prevState.managementTab,
                            firstPositionCode: primaryPosition,
                            secondPositionCode: secondPosition,
                        },
                    })),
                );
            }
        };
    }

    public static setRemoveReason(reason: RemoveReasonsListItem) {
        return (dispatch) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    managementTab: {
                        ...prevState.managementTab,
                        selectedRemoveReason: reason,
                    },
                })),
            );
        };
    }

    public static removePlayer() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    managementTab: {
                        ...prevState.managementTab,
                        processing: true,
                    },
                })),
            );

            try {
                const { playerId, selectedRemoveReason } =
                    Selectors.getManagementTabState(getState());

                await TransfersOutPlayerDatailsService.deletePlayer(
                    playerId,
                    selectedRemoveReason?.name,
                );

                dispatch(SquadController.removePlayer(playerId));
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        managementTab: {
                            ...prevState.managementTab,
                            processing: false,
                        },
                    })),
                );
                dispatch(Actions.dispose());
                historyAccessor.push(userPaths.transfersOut);
            }
        };
    }

    public static updateSelectedPlayer(player: MySquadPlayer) {
        return async (dispatch) => {
            dispatch(stateController.setState({ selectedPlayer: { ...player } }));
        };
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.transfersOut.playerDetails;
    public static getSelectedPlayer = (state: AppState) => Selectors.getRoot(state).selectedPlayer;
    public static getActiveTab = (state: AppState) => Selectors.getRoot(state).activeTab;
    public static getPlayer = (state: AppState) => {
        const player = Selectors.getSelectedPlayer(state);
        if (player?.toBuy) {
            if (player.toBuy.request) {
                player.toBuy.askingPrice = null;
                player.toBuy.sellOn = null;
            }
            player.toBuy.askingPrice =
                player.toBuy.askingPrice == null
                    ? player.toBuy.askingPrice
                    : formatWithCommas(player.toBuy.askingPrice);
        }
        if (player?.toLoan) {
            if (player.toLoan.request) {
                player.toLoan.monthlyLoan = null;
            }
            player.toLoan.monthlyLoan =
                player.toLoan.monthlyLoan === null
                    ? player.toLoan.monthlyLoan
                    : formatWithCommas(player.toLoan.monthlyLoan);
        }
        return player;
    };
    public static getManagementTabState = (state: AppState) =>
        Selectors.getRoot(state).managementTab;
    public static getManagementActiveTab = (state: AppState) =>
        Selectors.getManagementTabState(state).activeTabId;
    public static isProcessing = (state: AppState) =>
        Selectors.getManagementTabState(state).processing;
    public static getContractExpireDate = (state: AppState) =>
        Selectors.getManagementTabState(state).contractExpireDate;
    public static getCanSaveContractExpiry = (state: AppState) => {
        const currentExpirationDate = Selectors.getSelectedPlayer(state).contractExpiration;
        const newExpirationDate = Selectors.getContractExpireDate(state);

        return (
            newExpirationDate &&
            moment(newExpirationDate).isValid() &&
            !moment(currentExpirationDate).isSame(moment(newExpirationDate))
        );
    };
    public static getVideoUrl = (state: AppState) => Selectors.getManagementTabState(state).url;
    public static getCanSaveVideo = (state: AppState) => Selectors.getVideoUrl(state)?.length > 0;
    public static getPrimaryPositionCode = (state: AppState) =>
        Selectors.getManagementTabState(state).firstPositionCode;
    public static getSecondaryPositionCode = (state: AppState) =>
        Selectors.getManagementTabState(state).secondPositionCode;
    public static getCanSavePlayerPositions = (state: AppState) => {
        const selectedPlayer = Selectors.getSelectedPlayer(state);
        const selectedPrimaryPosition = Selectors.getPrimaryPositionCode(state);
        const selectedSecondaryPosition = Selectors.getSecondaryPositionCode(state);

        return (
            selectedPrimaryPosition !== selectedPlayer.firstPositionCode ||
            selectedSecondaryPosition !== selectedPlayer.secondPositionCode
        );
    };
    public static getRemovePlayerReason = (state: AppState) =>
        Selectors.getManagementTabState(state).selectedRemoveReason;
    public static isPlayerAdMismatchOpen = (state: AppState) =>
        Selectors.getRoot(state).isPlayerAdMismatchModelOpen;
    public static playerMismatches = (state: AppState) => Selectors.getRoot(state).mismatches;
}

const reducer = stateController.getReducer();

export {
    Selectors as Selectors,
    reducer as Reducer,
    PlayerDetailsState as State,
    Actions as Actions,
    stateController as Controller,
};
