import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration';
import { Actions as RequirementsActions } from 'pages/club/transfers-in/redux/transfers-in-requirements.controller';
import { Actions as RibbonActions } from 'pages/club/transfers-in/redux/transfers-in-top-ribbon.controller';
class TransfersInCommonState {
    isLoading: boolean;
}

const defaultState: TransfersInCommonState = {
    isLoading: false,
};

const stateController = new StateController<TransfersInCommonState>(
    'SQUAD_TRANSFERS_IN/COMMON',
    defaultState,
);

class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState));
        };
    }

    public static initTransfersInData() {
        return async (dispatch) => {
            dispatch(stateController.setState({ isLoading: true }));
            try {
                await dispatch(RequirementsActions.getRequirementsData());
                await dispatch(RibbonActions.getRibbonData());
            } catch (error) {
                console.error(error);
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        };
    }

    public static getPitches() {
        return async (dispatch, getState: () => AppState) => {
            try {
            } catch (error) {
                console.error(error);
            }
        };
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.transfersIn.common;
    public static isLoading = (state: AppState) => Selectors.getRoot(state).isLoading;
}

const reducer = stateController.getReducer();

export {
    Selectors as Selectors,
    reducer as Reducer,
    TransfersInCommonState as State,
    Actions as Actions,
    stateController as Controller,
};
