import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import historyAccessor from 'history-accessor';
import { openPlusPitchCreateModal } from 'app/pitch-plus/create-flow/store/pitch-plus-create.service';
import { plusPitchesFetch } from 'pages/Pitch/tab-plus-pitch/store/actions';
import { getClubUserPermissions } from 'store/userPreference/userPreference.reducer';
import { SubscriptionPlanEnum } from 'constants/enums';
import { userPaths } from 'routes/paths';
import { MessagingService } from 'api/messaging/messaging.service';
import { Actions as CommonActions } from 'pages/player-ads/redux/common.controller';
import { Actions as MessagingActions } from 'app/messages/store/messages.controller';
import { loadUnreadMessageCount } from 'store/liveData/liveData.service';
import { MODAL_OPEN } from 'store/actionTypes';
import { Actions as RequirementsActions } from 'pages/club/transfers-in/redux/transfers-in-requirements.controller';
import { FRIENDLY_MATCH_EDITOR_MODAL, SELECT_POSITION_MODAL } from 'constants/modals';
export enum CreateActionTypesEnum {
    PitchPlayer = 1,
    PlusPitchPlayer = 2,
    SuperPitchPlayer = 3,
    PostRequirement = 4,
    ArrangeFriendly = 5,
    SendAMessage = 6,
}
class LayoutState {
    isSideBarCollapsed: boolean;
    isHamburgerMenuOpen: boolean | null;
    isLoginDropdownOpen: boolean;
    isCreateModalOpen: boolean;
    numberOfTransfersIn?: number;
    numberOfUnreadMessages?: number;
}

const defaultState: LayoutState = {
    isSideBarCollapsed: false,
    isHamburgerMenuOpen: null,
    isLoginDropdownOpen: false,
    isCreateModalOpen: false,
};

const stateController = new StateController<LayoutState>('LAYOUT', defaultState);

class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }));
        };
    }

    public static setSidebarCollapsed() {
        return (dispatch, getState: () => AppState) => {
            const isCollapsed = getState().layout.isSideBarCollapsed;
            dispatch(stateController.setState({ isSideBarCollapsed: !isCollapsed }));
        };
    }

    public static init() {
        return async (dispatch) => {
            dispatch(loadUnreadMessageCount());
            dispatch(CommonActions.loadCounters());
            dispatch(MessagingActions.checkNewMessageLimit());
        };
    }

    public static setHamburgerMenu() {
        return (dispatch, getState: () => AppState) => {
            const isOpen = getState().layout.isHamburgerMenuOpen;
            dispatch(stateController.setState({ isHamburgerMenuOpen: !isOpen }));
        };
    }

    public static toggleLoginDropdown() {
        return (dispatch, getState: () => AppState) => {
            const visible = Selectors.getLoginDropdownVisibility(getState());
            const isHamburgerMenuOpen = getState().layout.isHamburgerMenuOpen;

            if (isHamburgerMenuOpen) {
                dispatch(stateController.setState({ isHamburgerMenuOpen: null }));
            }

            dispatch(stateController.setState({ isLoginDropdownOpen: !visible }));
        };
    }

    public static toggleCreatePopup() {
        return (dispatch, getState: () => AppState) => {
            const visible = Selectors.getCreatePopupVisibility(getState());
            dispatch(stateController.setState({ isCreateModalOpen: !visible }));
        };
    }

    public static createSelectedAction(type: CreateActionTypesEnum) {
        return (dispatch, getState: () => AppState) => {
            const availiblePlusPitces = Selectors.getPlusPitchesCount(getState());

            if (type === CreateActionTypesEnum.PlusPitchPlayer && availiblePlusPitces > 0) {
                dispatch(Actions.openCreatePlusPitchModal());
            }
            if (type === CreateActionTypesEnum.PitchPlayer) {
                dispatch(Actions.redirectToPitchOpportunities());
            }
            if (type === CreateActionTypesEnum.PostRequirement) {
                dispatch(Actions.redirectToTransfersIn());
            }
            if (type === CreateActionTypesEnum.ArrangeFriendly) {
                dispatch(Actions.redirectToFriendlies());
                dispatch(Actions.openCreateNewFriendlyMatchAd());
            }
            if (type === CreateActionTypesEnum.SendAMessage) {
                dispatch(Actions.redirectToMessages());
                //dispatch(Actions.openCreateNewFriendlyMatchAd());
            }
            dispatch(Actions.toggleCreatePopup());
        };
    }

    public static redirectToPitchOpportunities() {
        return async (dispatch) => {
            historyAccessor.push(userPaths.pitchOpportunities);
        };
    }

    public static redirectToTransfersIn() {
        return async (dispatch) => {
            historyAccessor.push(userPaths.transfersIn);
            const command = {
                type: MODAL_OPEN,
                payload: {
                    id: SELECT_POSITION_MODAL,
                    customToggle: () => (dispatch) => {
                        dispatch(RequirementsActions.closeSelectPositionModal());
                    },
                },
            };
            dispatch(command);
        };
    }

    public static redirectToFriendlies() {
        return () => {
            historyAccessor.push(userPaths.friendlies);
        };
    }

    public static redirectToMessages() {
        return (dispatch) => {
            historyAccessor.push(userPaths.messagingPage);
            dispatch(MessagingActions.changeSearchMode(true));
        };
    }

    public static openCreateNewFriendlyMatchAd() {
        return (dispatch) => {
            historyAccessor.push(userPaths.friendlies);
            const command = {
                type: MODAL_OPEN,
                payload: {
                    id: FRIENDLY_MATCH_EDITOR_MODAL,
                },
            };
            dispatch(command);
        };
    }

    public static openCreatePlusPitchModal() {
        return async (dispatch) => {
            dispatch(plusPitchesFetch());
            dispatch(openPlusPitchCreateModal());
        };
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.layout;
    public static getLoginDropdownVisibility = (state: AppState) =>
        Selectors.getRoot(state).isLoginDropdownOpen;
    public static getCreatePopupVisibility = (state: AppState) =>
        Selectors.getRoot(state).isCreateModalOpen;
    public static getIsCollapsed = (state: AppState) => Selectors.getRoot(state).isSideBarCollapsed;
    public static getPlusPitchesCount = (state: AppState) =>
        getClubUserPermissions(state)?.allowedPlusPitchesCount;
    public static getCanSendMessages = (state: AppState) =>
        state.auth.clubPermission.canSendMessages;
    public static getNumberOfAvailableMessages = (state: AppState) =>
        state.messages.newMessageAvailability?.availableCount ?? 0;
    public static isVIPUser = (state: AppState) => {
        const subPlanId = state.auth.subscriptionPlan;

        return (
            subPlanId === SubscriptionPlanEnum.Vip || subPlanId === SubscriptionPlanEnum.SuperVip
        );
    };
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    LayoutState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller,
};
