export class GeneralPosition {
    id: number;
    code: string;
    name: string;
}

export const positions: Array<GeneralPosition> = [
    { id: 6, name: 'Goalkeeper', code: 'GK' },
    { id: 7, name: 'Left Back', code: 'LB' },
    { id: 8, name: 'Right Back', code: 'RB' },
    { id: 3, name: 'Centre Back', code: 'CB' },
    { id: 4, name: 'Defensive Midfielder', code: 'DM' },
    { id: 2, name: 'Central Midfielder', code: 'CM' },
    { id: 1, name: 'Attacking Midfielder', code: 'AM' },
    { id: 9, name: 'Winger', code: 'W' },
    { id: 5, name: 'Forward', code: 'F' },
];

export const positionsHackedForSomeReasonNotByMe: Array<GeneralPosition> = [
    { id: 6, name: 'Goalkeeper', code: 'GK' },
    { id: 7, name: 'Left Back', code: 'LB' },
    { id: 8, name: 'Right Back', code: 'RB' },
    { id: 3, name: 'Centre Back', code: 'CB' },
    { id: 4, name: 'Defensive Midfielder', code: 'DM' },
    { id: 2, name: 'Central Midfielder', code: 'CM' },
    { id: 1, name: 'Attacking Midfielder', code: 'AM' },
    { id: 9, name: 'Winger', code: 'W' },
    { id: 5, name: 'Forward', code: 'F' },
    { id: 10, name: 'Centre Back (all)', code: 'CB(all)' },
    { id: 11, name: 'Centre Back (left-footed only)', code: 'CB(left foot)' },
];

export const getPositionFullName = (pos: string) => {
    if (pos === 'F') return 'Forward';
    if (pos === 'W') return 'Winger';
    if (pos === 'AM') return 'Attacking Midfielder';
    if (pos === 'CM') return 'Central Midfielder';
    if (pos === 'DM') return 'Defensive Midfielder';
    if (pos === 'LB') return 'Left Back';
    if (pos === 'RB') return 'Right Back';
    if (pos === 'CB') return 'Centre Back';
    if (pos === 'GK') return 'Goalkeeper';
};

export const allPositionKeys = {
    Goalkeeper: 'GK',
    LeftBack: 'LB',
    RightBack: 'RB',
    CentreBack: 'CB',
    DefensiveMidfielder: 'DM',
    CentralMidfielder: 'CM',
    AttackingMidfielder: 'AM',
    Winger: 'W',
    Forward: 'F',
};

export const getPositionCodeByName = (name: string): string => {
    const pos = positionsHackedForSomeReasonNotByMe.find((x) => x.name == name);
    if (pos) return pos.code;
    else return null;
};

export const getPositionCodeByCode = (code: string): string => {
    const pos = positions.find((x) => x.code == code);
    if (pos) return pos.code;
    else return null;
};

export const getPositionCodeById = (id: number): string => {
    let pos = positions.find((x) => x.id == id);
    if (pos) return pos.code;
    else return null;
};

export const getPositionNameById = (id: number): string => {
    let pos = positions.find((x) => x.id == id);
    if (pos) return pos.name;
    else return null;
};

export const getPositionIdByCode = (code: string): number => {
    let pos = positions.find((x) => x.code == code);
    if (pos) return pos.id;
    else return null;
};

export const getPositionNameByCode = (code: string): string => {
    let pos = positions.find((x) => x.code == code);
    if (pos) return pos.name;
    else return null;
};
export const getHackedPositionNameByCode = (code: string): string => {
    let pos = positionsHackedForSomeReasonNotByMe.find((x) => x.code == code);
    if (pos) return pos.name;
    else return null;
};
