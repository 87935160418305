import axios from 'axios-config';
import {
    OpportunitiesResponse,
    RecentPitches,
    MySquadResponse,
    PitchItem,
} from 'api/transfers-out/models/transfers-out';

export default class TransfersOutService {
    public static async getRecentPitches(): Promise<RecentPitches> {
        const { data } = await axios.get(`api/v2/transfer-out/recent-pitches`);
        return data;
    }

    public static async getOpportunities(): Promise<OpportunitiesResponse> {
        const { data } = await axios.get(`api/v2/transfer-out/opportunities`);
        return data;
    }

    public static async opportunityDecline(createdBySquadId: number, playerAdId: number) {
        return await axios.post(`api/v2/transfer-out/opportunity-decline`, {
            createdBySquadId,
            playerAdId,
        });
    }

    public static async getMySquad(): Promise<MySquadResponse> {
        const { data } = await axios.get(`api/v2/transfer-out/my-squad`);
        return data;
    }

    public static async loadRecommendedPlayersBanner(): Promise<any> {
        const { data } = await axios.get(`api/v2/MySquad/ShowBanner`);
        return data;
    }

    public static async matchMakerFetch(squadId: number, userId: number): Promise<any> {
        const { data } = await axios.get(
            `api/MySquad/GetMySquadRecommendationPerPlayer/${squadId}/${userId}`,
        );
        return data;
    }

    public static async makePlayerNotAvailable(playerId: number) {
        return await axios.post(`api/v2/transfer-out/not-available`, {
            playerId,
        });
    }

    public static async makePlayerAvailable(playerId: number) {
        return await axios.post(`api/v2/transfer-out/not-available`, {
            playerId,
        });
    }

    public static async getSavedPitches(): Promise<PitchItem[]> {
        const { data } = await axios.get(`api/v2/transfer-out/saved-pitches`);
        return data;
    }
    public static async getPitchesAd(playerAdIds: number[]): Promise<OpportunitiesResponse> {
        const { data } = await axios.post(`api/v2/transfer-out/opportunities-filtered`, {
            playerAdIds: playerAdIds,
        });
        return data;
    }

    public static async getPlayerPitchesAds(playerIds: number[]): Promise<OpportunitiesResponse> {
        const { data } = await axios.post(`api/v2/transfer-out/opportunities-filtered`, {
            playerIds: playerIds,
            is60PercentsRelevancePlayer: true,
        });
        return data;
    }
}
