import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration';
import historyAccessor from 'history-accessor';
import { userPaths } from 'routes/paths';

import TransfersInService from 'api/transfers-in/transfers-in.service';
import { MyRequirementsPosition } from 'api/transfers-in/models/transfers-in-requirements';
import { MODAL_CLOSE } from 'store/actionTypes';
import { SELECT_POSITION_MODAL } from 'constants/modals';

class TransfersInRequirementsState {
    isLoading: boolean;
    myRequirements: MyRequirementsPosition[];
    processingRequirement: MyRequirementsPosition;
}

const defaultState: TransfersInRequirementsState = {
    isLoading: false,
    myRequirements: [],
    processingRequirement: null,
};

const stateController = new StateController<TransfersInRequirementsState>(
    'SQUAD_TRANSFER_IN/REQUIREMENTS',
    defaultState,
);

class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState));
        };
    }

    public static getRequirementsData() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true, processingRequirement: null }));
            try {
                const { items } = await TransfersInService.getAdsPitches();

                if (items) {
                    dispatch(stateController.setState({ myRequirements: items }));
                }
            } catch (error) {
                console.error(error);
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        };
    }

    public static openCreateRequirementModal(requirement: MyRequirementsPosition) {
        return (dispatch) => {
            dispatch(stateController.setState({ processingRequirement: requirement }));
            historyAccessor.push(userPaths.createPlayerRequirements);
        };
    }

    public static setSelectedRequirement(positionId?: number) {
        return (dispatch, getState: () => AppState) => {
            if (!positionId) {
                dispatch(stateController.setState({ processingRequirement: undefined }));
            }
            const requirements = Selectors.getMyRequirements(getState());
            var requirement = requirements.find((x) => x.generalPositionId === positionId);

            dispatch(stateController.setState({ processingRequirement: requirement }));
        };
    }

    public static closeSelectPositionModal() {
        return async (dispatch) => {
            let command = {
                type: MODAL_CLOSE,
                payload: SELECT_POSITION_MODAL,
            };
            dispatch(command);
        };
    }

    public static redirectToCreateRequirement() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(Actions.closeSelectPositionModal());
            const selectedRequirement = Selectors.getSelectedRequirement(getState());
            dispatch(Actions.openCreateRequirementModal(selectedRequirement));
        };
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.transfersIn.requirements;
    public static getMyRequirements = (state: AppState) => Selectors.getRoot(state).myRequirements;
    public static getActiveRequirements = (state: AppState) =>
        Selectors.getRoot(state).myRequirements.filter((x) => x.isActive);
    public static getSelectedRequirement = (state: AppState) =>
        Selectors.getRoot(state).processingRequirement;
    public static getIsLoading = (state: AppState) => Selectors.getRoot(state).isLoading;
}

const reducer = stateController.getReducer();

export {
    Selectors as Selectors,
    reducer as Reducer,
    TransfersInRequirementsState as State,
    Actions as Actions,
    stateController as Controller,
};
