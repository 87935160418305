import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration';
import { Actions as SquadController } from 'pages/club/transfers-out/redux/transfers-out-my-squad-controller';
import TransfersOutService from 'api/transfers-out/transfers-out.service';
import { MySquadPlayer } from 'api/transfers-out/models/transfers-out';
import { Actions as CommonActions } from 'pages/club/transfers-out/redux/transfers-out-common.controller';
import { Selectors as TransfersOutMySquadSelectors } from 'pages/club/transfers-out/redux/transfers-out-my-squad-controller';

class TransfersOutPlayerState {
    loading: boolean;
    player: MySquadPlayer;
}

const defaultState: TransfersOutPlayerState = {
    loading: false,
    player: null,
};

const stateController = new StateController<TransfersOutPlayerState>(
    'SQUAD_TRANSFER_OUT/PLAYER',
    defaultState
);

class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState));
        };
    }

    public static getInitialData(id: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ loading: true }));
            try {

                await dispatch(CommonActions.getOpportunities());
                await dispatch(SquadController.initMySquadData());

                const player = TransfersOutMySquadSelectors.getRoot(getState()).mySquad.find((item) => item.id === id)
                dispatch(SquadController.setActivePlayer(player));

                dispatch(stateController.setState({ player: player }));
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ loading: false }));
            }
        };
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.transfersOut.player;
    public static getPlayer = (state: AppState) => Selectors.getRoot(state).player;
    public static getIsLoading = (state: AppState) => Selectors.getRoot(state).loading;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    Selectors as Selectors,
    TransfersOutPlayerState as State,
    Actions as Actions,
    stateController as Controller,
};
