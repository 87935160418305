export const SEND_MESSAGE_MODAL = 'SEND_MESSAGE_MODAL';
export const MATCH_MAKER_MODAL = 'MATCH_MAKER_MODAL';
export const MATCH_MAKER_CLUB_ADS_MODAL_V2 = 'MATCH_MAKER_CLUB_ADS_MODAL_V2';
export const COMPETITION_LISTS_MODAL = 'COMPETITION_LISTS_MODAL';
export const ACCESS_RESTRICTED_MODAL = 'ACCESS_RESTRICTED_MODAL';
export const LANDING_PAGE_MODAL_FRIENDLY_ADS = 'LANDING_PAGE_MODAL_FRIENDLY_ADS';

export const PLAYER_AD_CREATOR_MODAL = 'PLAYER_AD_CREATOR_MODAL';

export const FRIENDLY_MATCH_EDITOR_MODAL = 'FRIENDLY_MATCH_EDITOR_MODAL';
export const INVITATIONS_DIALOG_MODAL = 'INVITATIONS_DIALOG_MODAL';
export const MATCH_FRIENDLY_ADS_MATCH_MODAL = 'MATCH_FRIENDLY_ADS_MATCH_MODAL';
export const SUGGESTION_FRIENDLY_ADS_MATCH_MODAL = 'SUGGESTION_FRIENDLY_ADS_MATCH_MODAL';

export const USER_EDITOR_MODAL = 'USER_EDITOR_MODAL';
export const USER_REGISTRATION_MODAL = 'USER_REGISTRATION_MODAL';
export const CHANGE_PASSWORD_MODAL = 'CHANGE_PASSWORD_MODAL';

// New Super Pitch
export const SUPER_PITCH_TEST_INTEREST_MODAL = 'SUPER_PITCH_TEST_INTEREST_MODAL';
export const SUPER_PITCH_CREATE = 'SUPER_PITCH_CREATE';
export const SUPER_PITCH_REMOVE = 'SUPER_PITCH_REMOVE';
export const SUPER_PITCH_VIEW = 'SUPER_PITCH_VIEW';
export const SUPER_PITCH_FLOW_STEP1_SELECT_PLAYER = 'SUPER_PITCH_FLOW_STEP1_SELECT_PLAYER';
export const SUPER_PITCH_FLOW_STEP2_SET_PRICE = 'SUPER_PITCH_FLOW_STEP2_SET_PRICE';
export const SUPER_PITCH_FLOW_STEP3_RECIPIENTS = 'SUPER_PITCH_FLOW_STEP3_RECIPIENTS';
export const SUPER_PITCH_FLOW_STEP4_MESSAGE = 'SUPER_PITCH_FLOW_STEP4_MESSAGE';
export const SUPER_PITCH_FLOW_STEP5_VIDEO = 'SUPER_PITCH_FLOW_STEP5_VIDEO';
export const SUPER_PITCH_FLOW_STEP6_COMPARISON = 'SUPER_PITCH_FLOW_STEP6_COMPARISON';
export const SUPER_PITCH_FLOW_STEP7_RSVP = 'SUPER_PITCH_FLOW_STEP7_RSVP';
export const SUPER_PITCH_FLOW_PREVIEW = 'SUPER_PITCH_FLOW_PREVIEW';

export const PITCH_PLUS_CREATE = 'PITCH_PLUS_CREATE';
export const PITCH_PLUS_STEP1_SELECT_PLAYER = 'PITCH_PLUS_STEP1_SELECT_PLAYER';
export const PITCH_PLUS_STEP2_PRICE = 'PITCH_PLUS_STEP2_PRICE';
export const PITCH_PLUS_STEP3_RECIPIENTS = 'PITCH_PLUS_STEP3_RECIPIENTS';
export const PITCH_PLUS_STEP4_MESSAGE = 'PITCH_PLUS_STEP4_MESSAGE';

export const CONTACT_US_MODAL = 'CONTACT_US_MODAL';

export const TEST_INTEREST_MODAL = 'TEST_INTEREST_MODAL';

export const MY_SQUAD_EDIT_RELEASE_LIST = 'MY_SQUAD_EDIT_RELEASE_LIST';

export const PITCH_INSIGHT_DETAILED_MODAL = 'PITCH_INSIGHT_DETAILED_MODAL';
export const UPGRADE_MODAL = 'UPGRADE_MODAL';

export const SUMMIT_SURVEY = 'SUMMIT_SURVEY';

export const MAKE_PLAYERS_AVAILABLE = 'MAKE_PLAYERS_AVAILABLE';
export const LANDING_PITCH_PLAYERS = 'LANDING_PITCH_PLAYERS';
export const CREATE_PLAYER_ADS = 'CREATE_PLAYER_ADS';
export const ADD_USERS = 'ADD_USERS';
export const BOOK_EXPERT_SESSION_MODAL = 'BOOK_EXPERT_SESSION_MODAL';

export const REMOVE_USER_WARNING_MODAL = 'REMOVE_USER_WARNING_MODAL';
export const SUMMIT_SEPTEMBER_AD_MODAL = 'SUMMIT_SEPTEMBER_AD_MODAL';
export const DELTE_PROFILE_WARNING = 'DELTE_PROFILE_WARNING';

export const EMAIL_LANDING_CLUB_ADS_RECOMMENDATIONS_DONE =
    'EMAIL_LANDING_CLUB_ADS_RECOMMENDATIONS_DONE';
export const EMAIL_LANDING_PLAYER_PROPOSALS_DONE = 'EMAIL_LANDING_PLAYER_PROPOSALS_DONE';
export const EMAIL_LANDING_OUTDATEDADS_DONE = 'EMAIL_LANDING_OUTDATEDADS_DONE';
export const EMAIL_LANDING_FRIENDLY_DONE = 'EMAIL_LANDING_FRIENDLY_DONE';
export const V2_PLAYER_AD_EDITOR = 'V2_PLAYER_AD_EDITOR';
export const DEACTIVATE_AGENTS_FEATURE = 'DEACTIVATE_AGENTS_FEATURE';

export const EVENT_VIDEO_MODAL = 'EVENT_VIDEO_MODAL';
export const EVENT_BLOG_IFRAME_MODAL = 'EVENT_BLOG_IFRAME_MODAL';

export const AGENCY_DELTE_PLAYER_WARNING = 'AGENCY_DELTE_PLAYER_WARNING';

export const PLAYER_TERMS_OF_SERVICE = 'PLAYER_TERMS_OF_SERVICE';
export const PLAYER_HEALTH_CONSENT = 'PLAYER_HEALTH_CONSENT';
export const ADMIN_AGENCY_RENEW_SUBSCRIPTION_MODAL = 'ADMIN_AGENCY_RENEW_SUBSCRIPTION_MODAL';

export const COACH_TERMS_OF_SERVICE = 'COACH_TERMS_OF_SERVICE';
export const COACH_HEALTH_CONSENT = 'COACH_HEALTH_CONSENT';
export const STAFF_TERMS_OF_SERVICE = 'STAFF_TERMS_OF_SERVICE';

export const MODAL_Z_INDEX = 1101;

export const SELECT_POSITION_MODAL = 'SELECT_POSITION_MODAL';
