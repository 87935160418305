import * as redux from 'redux';
import * as GridController from 'pages/club/transfers-in/components/saved-pitches/redux/grid.controller';
import * as FilterController from 'pages/club/transfers-in/components/saved-pitches/redux/filter.controller';

export class SavedPitchesState {
    savedPitchesGrid: GridController.State;
    filterSavedPitches: FilterController.State;
}

export function combineReducers() {
    const SavedPitchesReducers = {
        savedPitchesGrid: GridController.Reducer,
        filterSavedPitches: FilterController.Reducer,
    };

    return redux.combineReducers(SavedPitchesReducers);
}
