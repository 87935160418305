import {
    PITCH_PLUS_STEP1_SELECT_PLAYER,
    PITCH_PLUS_STEP2_PRICE,
    PITCH_PLUS_STEP3_RECIPIENTS,
    PITCH_PLUS_STEP4_MESSAGE,
    PITCH_PLUS_CREATE
} from 'constants/modals';
import {
    MODAL_OPEN,
    MODAL_CLOSE,
    SUPER_PITCH_FETCH_COMPETITONS_WITH_SQUAD,
    SUPER_PITCH_CREATE_SUPER_PITCH,
    USER_PREFERENCE_FETCH,
    GET_RECOMMENDED_SQUADS_BY_PLAYER_ID_AND_PRICE,
    SUPER_PITCH_FETCH_SQUAD_PLAYERS
} from 'store/actionTypes';
import { userPaths } from "routes/paths"
import createApiThunk from 'services/createApiThunk';
import {
    SET_PITCHPLUS_STEP1,
    SET_PITCHPLUS_STEP2,
    SET_PITCHPLUS_STEP3,
    SET_PITCHPLUS_STEP4,
    CLEAR_PITCHPLUS_CREATE,
    SUPER_PITCH_CREATE_DISPOSE
} from './pitch-plus.actions';
import {
    getAuthAspId,
    getAuth,
} from 'store/auth/authReducer';
import { getRequests } from 'store/requests/requestsReducer';
import { loadUserPreferenceInfo } from 'store/userPreference/userPreference.service';
import { plusPitchesFetch } from 'pages/Pitch/tab-plus-pitch/store/actions';
import { PageType } from 'constants/enums';
import { getClubUserPermissions } from 'store/userPreference/userPreference.reducer';
import { Selectors as MySquadFilterSelectors } from 'pages/my-squad-v2/redux/my-squad-filter.controller';

export const initDataForSuperPitchCretion = () => {
    return async (dispatch, getState) => {

        let state = getState();
        let allowedPlusPitchesCount = getClubUserPermissions(state)?.allowedPlusPitchesCount ?? 0;
        if (allowedPlusPitchesCount === 0) {
            window.location.replace(userPaths.mySquad);
            return;
        }
        const playersRequest = MySquadFilterSelectors.getMySquadFetchRequest(state);
        const {
            clubId,
            squadId,
            userId,
            criteria
        } = playersRequest;

        const defaultPagination = {
            currentPageNumber: 1,
            pageSize: 10000,
        };
        const payload = { ...criteria, ...defaultPagination };

        const fetchSquadRequest = {
            type: SUPER_PITCH_FETCH_SQUAD_PLAYERS,
            method: 'post',
            scope: 'superPitchCreateFlow',
            url: `MySquad/GetPlayersByFilterWithPagination/${clubId}/${squadId}/${userId}`,
            payload,
            success: SUPER_PITCH_FETCH_SQUAD_PLAYERS.SUCCESS,
        };

        dispatch(createApiThunk(fetchSquadRequest));

        const request = {
            type: SUPER_PITCH_FETCH_COMPETITONS_WITH_SQUAD,
            method: 'get',
            scope: 'superPitchCreateFlow',
            url: `Competition/GetCompetitionWithActiveSquads?showOnlyPrimary=true`,
            success: SUPER_PITCH_FETCH_COMPETITONS_WITH_SQUAD.SUCCESS,
        };

        dispatch(createApiThunk(request));
        dispatch(loadUserPreferenceInfo());
    };
};

export const getRecommendedSquadsByPlayerIdAndPrice = () => {
    return async (dispatch, getState) => {

        let state = getState();
        let allowedPlusPitchesCount = getClubUserPermissions(state)?.allowedPlusPitchesCount ?? 0;
        if (allowedPlusPitchesCount === 0) {
            window.location.replace(userPaths.mySquad);
            return;
        }

        let pp = state.pitchPlusCreate;
        
        const request = {
            type: GET_RECOMMENDED_SQUADS_BY_PLAYER_ID_AND_PRICE,
            method: 'get',
            scope: 'plusPitchCreateFlow',
            url: `Squad/GetRecommendedSquadsByPlayerIdAndPrice/${pp.step1.player.id}/${(pp.step2.forSale) || -1}/${(pp.step2.forLoan) || -1}`,
            success: GET_RECOMMENDED_SQUADS_BY_PLAYER_ID_AND_PRICE.SUCCESS,
        };
        let res = await dispatch(createApiThunk(request));
        return res;
    };
};

export const stepsCompleteState = state => {
    let plusPitchState = state.pitchPlusCreate;
    let step1 = plusPitchState.step1.player != null;
    let step2 =
        plusPitchState.step2.forSale != null ||
        plusPitchState.step2.forLoan;
    let step3 = plusPitchState.step3.length > 0;
    return { step1, step2, step3 };
};

export const selectPlayer = id => {
    return (dispatch, getState) => {
        const player = getState().pitchPlusCreate.players.find(x => x.id === id);

        const command = {
            type: SET_PITCHPLUS_STEP1,
            payload: { player: player },
        };
        dispatch(command);
        dispatch(closeSelectPlayer());
    };
};

export const ClulbSelectionLimit = 20;

export const selectClub = clubdIds => {
    return (dispatch, getState) => {
        let command = {
            type: SET_PITCHPLUS_STEP3,
            payload: clubdIds,
        };
        dispatch(command);
        dispatch(closeResipientsModal());
    };
};

export const addMessage = message => {
    return (dispatch, getState) => {
        let command = {
            type: SET_PITCHPLUS_STEP4,
            payload: {message: message},
        };
        dispatch(command);
        dispatch(closeMessageModal());
    };
};

export const closeResipientsModal = () => {
    return dispatch => {
        let command = {
            type: MODAL_CLOSE,
            payload: PITCH_PLUS_STEP3_RECIPIENTS,
        };

        dispatch(command);
    };
};

export const closeMessageModal = () => {
    return dispatch => {
        let command = {
            type: MODAL_CLOSE,
            payload: PITCH_PLUS_STEP4_MESSAGE,
        };

        dispatch(command);
    };
};

export const closeSelectPlayer = () => {
    return dispatch => {
        let command = {
            type: MODAL_CLOSE,
            payload: PITCH_PLUS_STEP1_SELECT_PLAYER,
        };

        dispatch(command);
    };
};

export const openSelectPlayerModal = () => {
    return (dispatch, getState) => {
        let command = {
            type: MODAL_OPEN,
            payload: { id: PITCH_PLUS_STEP1_SELECT_PLAYER },
        };

        dispatch(command);
    };
};
export const openAddMessageModal = () => {
    return (dispatch, getState) => {
        let command = {
            type: MODAL_OPEN,
            payload: { id: PITCH_PLUS_STEP4_MESSAGE },
        };

        dispatch(command);
    };
};

export const openPlusPitchCreateModal = () => {
    return (dispatch, getState) => {
        let command = {
            type: MODAL_OPEN,
            payload: {
                id: PITCH_PLUS_CREATE,
                customToggle: () => (dispatch) => {
                    dispatch(closePlusPitchCreateModal())
                }
            },
        };
        dispatch(command);
    };
};

export const closePlusPitchCreateModal = () => {
    return dispatch => {
        let command = { type: MODAL_CLOSE, payload: PITCH_PLUS_CREATE, };
        dispatch(command);
    };
};

export const setPrice = (forSale, forLoan, sellOn) => {
    return (dispatch, getState) => {
        let command = {
            type: SET_PITCHPLUS_STEP2,
            payload: { forSale, forLoan, sellOn },
        };
        dispatch(command);
        dispatch(closeSetPrice());
    };
};

export const openSelectSetPriceModal = () => {
    return (dispatch, getState) => {
        let command = {
            type: MODAL_OPEN,
            payload: { id: PITCH_PLUS_STEP2_PRICE },
        };

        dispatch(command);
    };
};

export const closeSetPrice = () => {
    return dispatch => {
        let command = {
            type: MODAL_CLOSE,
            payload: PITCH_PLUS_STEP2_PRICE,
        };

        dispatch(command);
    };
};

export const openRecipientsModal = () => {
    return dispatch => {
        let command = {
            type: MODAL_OPEN,
            payload: { id: PITCH_PLUS_STEP3_RECIPIENTS },
        };

        dispatch(command);
    };
};

export const clearPitchForm = () => {
    return (dispatch, getState) => {
        let command = {
            type: CLEAR_PITCHPLUS_CREATE,
        };
        dispatch(command);
    };
};

export const processNewPitchCreate = () => {
    return async (dispatch, getState) => {
        let state = getState();
        const aspNetUserId = getAuthAspId(getState());
        let createSuperPitchState = state.pitchPlusCreate;

        const recommendedList = createSuperPitchState.recommendedSquads.find(({ id }) => id === -1) || {}
        const recommendedSquads = recommendedList.squads && recommendedList.squads.map(({ id }) => id)
        const allRecipients = createSuperPitchState.step3.map(i => i.id)

        let recipients = [];
        let recomender = [];

        for (let i = 0; i < createSuperPitchState.squads.length; i++) {
            const league = createSuperPitchState.squads[i];
            for (let j = 0; j < league.squads.length; j++) {
                const squad = league.squads[j];
                if (allRecipients.indexOf(squad.id) > -1) {
                    if(recommendedSquads && recommendedSquads.indexOf(squad.id) > -1) {
                        recomender.push(squad.name);
                    } else {
                        recipients.push(squad.name);
                    }
                    
                }
            }
        }
        const recipientsText = recipients[0] ?  `, Recipients: ${recipients.join(', ')}` : '';
        const recomenderText = recomender[0] ? `, Recommended: ${recomender.join(', ')}` : ''

        const player = createSuperPitchState.step1.player;

        let currency = getAuth(state).currency.name;
        /*
        if (createSuperPitchState.step2.forLoan !== null) {
            dispatch(
                userActivityInsert({
                    Message: `Launched Plus Pitch (Loan):  ${currency} ${
                        createSuperPitchState.step2.forLoan
                        }, ${recipientsText}`,
                    PageName: 'Plus Pitch',
                    PlayerId: player.id,
                    CurrentClubId: player.currentSquadId,
                    PageType: PageType.Pitch
                }),
            );
        }

        if (createSuperPitchState.step2.forSale !== null) {
            dispatch(
                userActivityInsert({
                    Message: `Launched Plus Pitch (Buy):  ${currency} ${
                        createSuperPitchState.step2.forSale
                        }, ${recipientsText}`,
                    PageName: 'Plus Pitch',
                    PlayerId: player.id,
                    ClubId: player.parentSquadId,
                    CurrentClubId: player.currentSquadId,
                    PageType: PageType.Pitch
                }),
            );
        }
*/
        let priceText = "";
        if(createSuperPitchState.step2.forSale !== null && createSuperPitchState.step2.forSale !== undefined){
            priceText += `For Sale (asking price: "${currency} ${createSuperPitchState.step2.forSale}"`;
            if(createSuperPitchState.step2.sellOn > 0) {
                priceText += `, Sell on: ${createSuperPitchState.step2.sellOn}%)`;
            }else{
                priceText += ')';
            }
        }

        if(createSuperPitchState.step2.forLoan !== null && createSuperPitchState.step2.forLoan !== undefined){
            if(createSuperPitchState.step2.forSale !== null && createSuperPitchState.step2.forSale !== undefined){
                priceText += ", "
            }
            priceText += `For Loan (asking price: "${currency} ${createSuperPitchState.step2.forLoan}")`;
        }

        const trackingObject = {
            Message: `Launched Plus Pitch: ${priceText}${recipientsText}${recomenderText}, Message${!!createSuperPitchState.step4.message ? '(Yes)' : '(No)'}`,
            PageName: 'Plus Pitch',
            PlayerId: player.id,
            CurrentClubId: player.currentSquadId,
            PageType: PageType.Pitch
        }

        let sellOn = createSuperPitchState.step2.sellOn;

        const request = {
            type: SUPER_PITCH_CREATE_SUPER_PITCH,
            method: 'post',
            scope: 'superPitchCreateFlow',
            url: `PlusPitch/Create/${aspNetUserId}`,
            payload: {
                playerId: createSuperPitchState.step1.player.id,
                salePrice: createSuperPitchState.step2.forSale,
                loanPrice: createSuperPitchState.step2.forLoan,
                sellOn: sellOn !== null ? parseInt(sellOn) : 0,
                message: createSuperPitchState.step4.message,
                squadIds: createSuperPitchState.step3
                .map(x => {
                    return{
                        squadId: x.id,
                        sourceId: x.isRecommended ? 2 : 1
                    }
                }) 
            },
            success: SUPER_PITCH_CREATE_SUPER_PITCH.SUCCESS,
        };

        await dispatch(createApiThunk(request, trackingObject));
        await dispatch(loadUserPreferenceInfo());
        await dispatch(plusPitchesFetch());

        // If this function process creation of the last plus pitch
        if (getClubUserPermissions(state)?.allowedPlusPitchesCount === 1) {
            dispatch(closePlusPitchCreateModal())
        }

    };
};

export const disposeSuperPitchCreate = () => {
    return (dispatch, getState) => {
        let command = {
            type: SUPER_PITCH_CREATE_DISPOSE,
        };
        dispatch(command);
    };
};

export const getLoadingStatus = state =>
    getRequests(state).some(
        r =>
            r.type === SUPER_PITCH_FETCH_SQUAD_PLAYERS ||
            r.type === SUPER_PITCH_FETCH_COMPETITONS_WITH_SQUAD ||
            r.type === USER_PREFERENCE_FETCH,
    );

export const isSavingStatus = state =>
    getRequests(state).some(
        r =>
            r.type === SUPER_PITCH_CREATE_SUPER_PITCH ||
            r.type === USER_PREFERENCE_FETCH,
    );
