import axios from 'axios-config';
import {
    MyRequirementsPositionsResponse,
    PositionRequirementsWithCountersResponse,
    CreatePositionRequirementsRequest,
    CreatePositionRequirementsResponse,
    PositionRequirementInitialInfoResponse,
} from 'api/transfers-in/models/transfers-in-requirements';
import { RibbonData } from 'api/transfers-in/models/transfers-in-top-ribbon';
import { CancelToken } from 'axios';
export default class TransfersInService {
    public static async getAdsPitches(): Promise<MyRequirementsPositionsResponse> {
        const { data } = await axios.get(`api/v2/transfer-in/my-ads-new-pitches`);
        return data;
    }

    public static async getRibbonData(): Promise<RibbonData> {
        const { data } = await axios.get(`api/v2/transfer-in/ribbon-data`);
        return data;
    }

    public static async getAllRequirementsWithCounters(
        cancelToken: CancelToken,
    ): Promise<Array<PositionRequirementsWithCountersResponse>> {
        const { data } = await axios.get(`api/v2/PlayerAd/GetAllWithCounters`, {
            cancelToken: cancelToken,
        });
        return data;
    }

    public static async createPositionRequirements(
        request: Array<CreatePositionRequirementsRequest>,
    ): Promise<CreatePositionRequirementsResponse> {
        const { data } = await axios.post(`api/v2/PlayerAd/CreateBatchV2`, request);
        return data;
    }

    public static async getPositionRequirementsInitialInfo(): Promise<PositionRequirementInitialInfoResponse> {
        const { data } = await axios.get(`api/v2/PlayerAd/InitialInfo`);
        return data;
    }

    public static async deletePositionRequirements(positionRequirementId: number): Promise<any> {
        const { data } = await axios.delete(
            `api/v2/PlayerAd/Delete?playerAdId=${positionRequirementId}`,
        );
        return data;
    }
}
