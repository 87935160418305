import { createBrowserHistory } from 'history';

type History = {
    push: (path: string) => void;
    replace: (path: string) => void;
    goBack: () => void;
};

const history: History = createBrowserHistory({
    // configuration
});
export default history;
