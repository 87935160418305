import { AppState } from 'root.reducer';
import { CancelTokenSource } from 'axios';
import { StateController } from 'utils/action-declaration';
import SearchService from 'api/search-v3/search.clubside.service';
import {
    SearchPlayerPosition,
    SearchPlayerPositionEnum,
} from 'api/search-v3/model/player-positions';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { getPositionCodeByName } from 'utils/position-helper';
import { PriceTypeEnum } from 'constants/enums';
import { Actions as GridActions } from 'pages/club/transfers-in/components/saved-pitches/redux/grid.controller';

export class FilterSavedPitchesState {
    positionsList: SearchPlayerPosition[];
    selectedPositionsList: string[];
    isPositionsLoading: boolean;
    transferTypeFilter: TransferTypeFilterModel;
    showClubPitchesFilter: boolean;
    showAgentPitchesFilter: boolean;
    filterCriteria: FilterCriteria;
}
export class FilterCriteria {
    isForLoan: boolean;
    isForSale: boolean;
    isLeftFooted: boolean;
    isHeadCoach: boolean;
    positions: string[];
}

export class TransferTypeFilterModel {
    filterToBuy: boolean;
    filterToLoan: boolean;
}

const defaultState: FilterSavedPitchesState = {
    positionsList: [],
    selectedPositionsList: [],
    isPositionsLoading: false,
    transferTypeFilter: {
        filterToBuy: false,
        filterToLoan: false,
    },
    showClubPitchesFilter: true,
    showAgentPitchesFilter: true,
    filterCriteria: {
        isForLoan: false,
        isForSale: false,
        isLeftFooted: false,
        isHeadCoach: false,
        positions: [],
    },
};

const stateController = new StateController<FilterSavedPitchesState>(
    'SQUAD_TRANSFERS_IN/SAVED_PITCHES_FILTER',
    defaultState,
);

class Actions {
    public static token: CancelTokenSource = null;

    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState }));
        };
    }

    public static loadPlayerPositions = () => {
        return async (dispatch) => {
            try {
                dispatch(stateController.setState({ isPositionsLoading: true }));
                const { positions } = await SearchService.getPlayerPositions();
                dispatch(stateController.setState({ positionsList: positions }));
            } catch (error) {
                console.error(error);
            } finally {
                dispatch(stateController.setState({ isPositionsLoading: false }));
            }
        };
    };

    public static setTransferTypeFilter(type: PriceTypeEnum) {
        return (dispatch) => {
            if (type === PriceTypeEnum.Buy) {
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        filterCriteria: {
                            ...prevState.filterCriteria,
                            isForSale: !prevState.filterCriteria.isForSale,
                        },
                    })),
                );
            } else {
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        filterCriteria: {
                            ...prevState.filterCriteria,
                            isForLoan: !prevState.filterCriteria.isForLoan,
                        },
                    })),
                );
            }
            dispatch(GridActions.setPagination());
        };
    }

    public static setPositionsFilter(position: SearchPlayerPosition) {
        return (dispatch, getState: () => AppState) => {
            const subState = Selectors.getFilterCriteria(getState()).positions;
            const positionCode = getPositionCodeByName(position.name);

            let filteredPosition = positionCode;

            if (positionCode === 'CB(all)' || positionCode === 'CB(left foot)') {
                filteredPosition = 'CB';
            }

            if (subState.includes(filteredPosition)) {
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        filterCriteria: {
                            ...prevState.filterCriteria,
                            positions: prevState.filterCriteria.positions.filter(
                                (item) => item !== filteredPosition,
                            ),
                        },
                    })),
                );
            } else {
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        filterCriteria: {
                            ...prevState.filterCriteria,
                            positions: [...prevState.filterCriteria.positions, filteredPosition],
                        },
                    })),
                );
            }

            if (positionCode === 'CB(left foot)') {
                const leftFooted = Selectors.getIsLeftFooted(getState());
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        filterCriteria: {
                            ...prevState.filterCriteria,
                            isLeftFooted: !leftFooted,
                        },
                    })),
                );
            }

            dispatch(Actions.setPositions(positionCode));
            dispatch(GridActions.setPagination());
        };
    }

    public static setPositions(positionCode: string) {
        return (dispatch, getState: () => AppState) => {
            const subState = Selectors.getSelectedPositions(getState());
            if (subState.includes(positionCode)) {
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        selectedPositionsList: prevState.selectedPositionsList.filter(
                            (item) => item !== positionCode,
                        ),
                    })),
                );
            } else {
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        selectedPositionsList: [...prevState.selectedPositionsList, positionCode],
                    })),
                );
            }
        };
    }

    public static setShowClubPitchesFilter(checked: boolean) {
        return (dispatch) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    showClubPitchesFilter: checked,
                })),
            );
            dispatch(GridActions.setPagination());
        };
    }

    public static setShowAgentPitchesFilter(checked: boolean) {
        return (dispatch) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    showAgentPitchesFilter: checked,
                })),
            );
            dispatch(GridActions.setPagination());
        };
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.transfersIn.savedPitches.filterSavedPitches;
    public static getPositions = (state: AppState) => Selectors.getRoot(state).positionsList;
    public static getTransferTypeFilter = (state: AppState) =>
        Selectors.getRoot(state).transferTypeFilter;

    public static getIsShowClubPitches = (state: AppState) =>
        Selectors.getRoot(state).showClubPitchesFilter;
    public static getIsShowAgentPitches = (state: AppState) =>
        Selectors.getRoot(state).showAgentPitchesFilter;
    public static getFilterCriteria = (state: AppState) => Selectors.getRoot(state).filterCriteria;
    public static getIncludeAgentPitches = (state: AppState) =>
        Selectors.getRoot(state).showAgentPitchesFilter;
    public static getincludeClubPitches = (state: AppState) =>
        Selectors.getRoot(state).showClubPitchesFilter;
    public static getIsLeftFooted = (state: AppState) =>
        Selectors.getFilterCriteria(state).isLeftFooted;
    public static getSelectedPositions = (state: AppState) =>
        Selectors.getRoot(state).selectedPositionsList;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    FilterSavedPitchesState as State,
    Actions,
    Selectors,
    stateController as Controller,
};
